import React from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'react-image-webp'

export default function HeroBetterme() {
  const { t } = useTranslation()
  return (
    <div className="">
      <div className="pt-3 pb-1.5 rounded-r15  mt-1  bg-grayB">
        <div className="before-after-images-block_labelsContainer__uiU4_ p-3  mt-0 flex items-center justif-between bg-white rounded-r12 m-3">
          <div className="w-full text-center font-girloy text-md border-r border-borderHeader  font-semibold">
            Now
          </div>
          <div></div>
          <div className="w-full text-center text-green font-girloy text-md font-semibold">
            Your Goal
          </div>
        </div>
        <div className="relative flex">
          <div className="w-full">
            <Image
              className="w-full"
              src="/img/betterme/range-3.jpg"
              webp="/img/betterme/range-3.webp"
              alt="body image"
            />
          </div>
          <div className="relative w-9 animation-arrows_container__Fvs3R animation-arrows_isAnimated__RKH3A">
            <svg
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows_animationTriangle1__s7j_d absolute top-1/2 -translate-y-1/2 right-1"
            >
              <path
                d="M3.28379 0.474938L13.6611 11.1135C13.7842 11.2401 13.8713 11.3773 13.9222 11.5251C13.9739 11.6728 13.9998 11.8311 13.9998 12C13.9998 12.1689 13.9739 12.3272 13.9222 12.4749C13.8713 12.6227 13.7842 12.7599 13.6611 12.8865L3.28379 23.5567C2.99639 23.8522 2.63714 24 2.20603 24C1.77493 24 1.40541 23.8417 1.09748 23.5251C0.789552 23.2084 0.635587 22.839 0.635587 22.4169C0.635587 21.9947 0.789552 21.6253 1.09748 21.3087L10.1507 12L1.09748 2.6913C0.810082 2.39578 0.666381 2.03188 0.666381 1.59958C0.666381 1.16644 0.820347 0.79156 1.12828 0.474938C1.43621 0.158315 1.79546 4.62499e-06 2.20604 4.6619e-06C2.61661 4.69881e-06 2.97586 0.158315 3.28379 0.474938Z"
                fill="#EA749B"
              ></path>
            </svg>
            <svg
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows_animationTriangle2__5PUEA absolute top-1/2 -translate-y-1/2 right-1"
            >
              <path
                d="M3.28379 0.474938L13.6611 11.1135C13.7842 11.2401 13.8713 11.3773 13.9222 11.5251C13.9739 11.6728 13.9998 11.8311 13.9998 12C13.9998 12.1689 13.9739 12.3272 13.9222 12.4749C13.8713 12.6227 13.7842 12.7599 13.6611 12.8865L3.28379 23.5567C2.99639 23.8522 2.63714 24 2.20603 24C1.77493 24 1.40541 23.8417 1.09748 23.5251C0.789552 23.2084 0.635587 22.839 0.635587 22.4169C0.635587 21.9947 0.789552 21.6253 1.09748 21.3087L10.1507 12L1.09748 2.6913C0.810082 2.39578 0.666381 2.03188 0.666381 1.59958C0.666381 1.16644 0.820347 0.79156 1.12828 0.474938C1.43621 0.158315 1.79546 4.62499e-06 2.20604 4.6619e-06C2.61661 4.69881e-06 2.97586 0.158315 3.28379 0.474938Z"
                fill="#EA749B"
              ></path>
            </svg>
            <svg
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows_animationTriangle3__vMb2G absolute top-1/2 -translate-y-1/2 right-1"
            >
              <path
                d="M3.28379 0.474938L13.6611 11.1135C13.7842 11.2401 13.8713 11.3773 13.9222 11.5251C13.9739 11.6728 13.9998 11.8311 13.9998 12C13.9998 12.1689 13.9739 12.3272 13.9222 12.4749C13.8713 12.6227 13.7842 12.7599 13.6611 12.8865L3.28379 23.5567C2.99639 23.8522 2.63714 24 2.20603 24C1.77493 24 1.40541 23.8417 1.09748 23.5251C0.789552 23.2084 0.635587 22.839 0.635587 22.4169C0.635587 21.9947 0.789552 21.6253 1.09748 21.3087L10.1507 12L1.09748 2.6913C0.810082 2.39578 0.666381 2.03188 0.666381 1.59958C0.666381 1.16644 0.820347 0.79156 1.12828 0.474938C1.43621 0.158315 1.79546 4.62499e-06 2.20604 4.6619e-06C2.61661 4.69881e-06 2.97586 0.158315 3.28379 0.474938Z"
                fill="#EA749B"
              ></path>
            </svg>
          </div>
          <div className="w-full">
            <Image
              className="w-full"
              src={'/img/res-body.png'}
              webp={'/img/res-body.webp'}
              alt="body image"
            />
          </div>
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-12 mt-5 px-3 mb-4 after:absolute after:content-[''] after:top-0 after:left-1/2 after:-translate-x-1/2 after:h-full after:w-px after:bg-borderHeader">
        <div>
          <div className="">
            <p className="opacity-70 text-xs2 font-medium leading-none">Body fat</p>
            <p className="font-semibold font-girloy leading-6">High</p>
            <p className="opacity-70 text-xs2 font-medium leading-none pt-2 mt-2 border-t border-borderHeader">
              Wall Pilates level
            </p>
            <p className="font-semibold font-girloy leading-6">Intermediate</p>
            <div className="flex gap-2 mt-2.5">
              <span className="w-full h-2 rounded-r2 bg-green2 block"></span>
              <span className="w-full h-2 rounded-r2 bg-green2 block"></span>
              <span className="w-full h-2 rounded-r2 bg-green2  opacity-20 block"></span>
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <p className="opacity-70 text-xs2 font-medium leading-none">Body fat</p>
            <p className="font-semibold font-girloy leading-6">Normal</p>
            <p className="opacity-70 text-xs2 font-medium leading-none pt-2 mt-2 border-t border-borderHeader">
              Wall Pilates level
            </p>
            <p className=" font-semibold font-girloy leading-6">{t('High')}</p>
            <div className="flex mt-2.5 gap-2">
              <span className="w-full h-2 rounded-r2 bg-green2 block"></span>
              <span className="w-full h-2 rounded-r2 bg-green2 block"></span>
              <span className="w-full h-2 rounded-r2 bg-green2 block"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
