import React, { useEffect, useState } from 'react'
import { Answer, Answers, ContentType, TestimonialsType } from '@/types/types'
import Review from './Review'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import stars from '@/assets/images/stars.svg'
import trustpilot from '@/assets/images/trustpilot-star.svg'
import Testimonials from './Testimonials'
import { useTranslation } from 'react-i18next'
import Image from 'react-image-webp'
import { getValue } from '@/helpers/getObjectValue'
import TestimonialsBetterme from './TestimonialsBetterme'

interface ContentProps {
  content: ContentType
  answers: Answers
  active?: boolean
  mode?: string
  onAnswer: (answer: Answer, step?: number) => void
}

export default function Content(props: ContentProps) {
  const arrayWithConditions =
    Array.isArray(props?.content?.conditions) && props?.content?.conditions?.length > 1
      ? props.content.conditions?.map((el) => {
          return el.value
        })
      : []
  const showCondition = props.content.conditions
    ? props.content.conditions.every((condition) => {
        const value = getValue(condition.fieldName, props.answers)
        if (typeof value === 'string') {
          return value === condition.value
        } else if (Array.isArray(value)) {
          return value.sort().toString() === arrayWithConditions.sort()?.toString()
        }
        return false
      })
    : false
  const { t } = useTranslation()
  const [loadAnimation, setLoadAnimation] = useState(false)

  useEffect(() => {
    if (props.active) {
      setLoadAnimation(true)
    } else {
      setLoadAnimation(false)
    }
  }, [props.active])

  if (props.content.conditions && !showCondition) {
    return null
  }

  return (
    <>
      {props?.content?.title && (
        <h2
          className={`${props.mode === 'betterme' ? 'pt-0 mt-4 font-girloy text-dark text-xl35 font-semibold mb-3' : 'pt-0 mt-4 text-dark text-xl4 font-bold mb-5'}`}
          dangerouslySetInnerHTML={{ __html: props?.content?.title }}
        ></h2>
      )}
      {props?.content?.smallParagraph && (
        <h3 className="mt-6 font-semibold mb-3 pl-6 uppercase text-md opacity-50">
          {props?.content?.smallParagraph}
        </h3>
      )}
      {props?.content?.image && !props?.content?.image?.srcWebp && (
        <div className={` mt-4 ${props?.content?.image?.classname === 'w-10' ? 'w-12' : 'w-full'}`}>
          <img
            className="max-w-full w-full rounded-xl"
            width={358}
            height={247}
            alt="Some content"
            src={props?.content?.image.src}
          />
        </div>
      )}
      {props?.content?.image?.srcWebp && (
        <div className={` mt-4 ${props?.content?.image?.classname === 'w-10' ? 'w-12' : 'w-full'}`}>
          <Image
            className="max-w-full w-full rounded-xl"
            width={358}
            height={247}
            alt="Some content"
            src={props?.content?.image.src}
            webp={props?.content?.image.srcWebp}
          />
        </div>
      )}
      {props?.content?.trustpilot && (
        <div className="-mb-3 flex items-start justify-start">
          <div className="font-extrabold flex items-center h-8">
            <img
              className="max-w-6 w-auto mr-1.5 block mb-px"
              width={20}
              height={20}
              alt="Trustpilot logo"
              src={trustpilot}
            />
            Trustpilot
          </div>
        </div>
      )}
      {props?.content?.trustpilotStars && (
        <div className="mt-3 flex items-start justify-start">
          <img
            className=" max-w-full w-auto block"
            width={106}
            height={20}
            alt="Stars"
            src={stars}
          />
        </div>
      )}
      {props?.content?.lottie && (
        <div className={loadAnimation ? 'graph-animation' : ''}>
          <div
            className={
              props?.content?.lottie.className &&
              'w-[0px] max-w-image -left-image-gap relative mt-6 sm:t-16 graph overflow-hidden graph-image'
            }
          >
            <div className="w-image max-w-image  relative">
              <span className="before absolute w-24 text-center  font-semibold text-white py-4 px-2 whitespace-nowrap bg-red rounded-lg block text-md2 opacity-0 top-6">
                Your weight
              </span>
              <span className="circle scale-0 bg-red w-4 h-4 rounded-full absolute block"></span>
              <span className="without absolute w-20 text-center  font-semibold text-white py-1.5 px-2  bg-[#C2C4CF] rounded-lg block text-md2 opacity-0">
                without Fit4me
              </span>
              <span className="after absolute w-20 text-center  font-semibold text-white py-1.5 px-2  bg-green rounded-lg block text-md2 opacity-0">
                with Fit4me
              </span>
              <div className="weeks text-md absolute w-full px-5 flex justify-between text-[#7A7A7A]">
                <span>week 1</span>
                <span>week 2</span>
                <span>week 3</span>
                <span>week 4</span>
              </div>
              <img className="w-full" src={props?.content?.lottie.src} alt="graph" />
            </div>
          </div>
          <div className="flex items-center justify-between -mt-8 relative z-10">
            <p className="text-lg">{t('* This plan based on Fit4me`s historical data')}</p>
          </div>
        </div>
      )}
      {props?.content?.paragraph && (
        <p
          className={`${props?.content?.paragraph.bold && 'font-bold'} ${props.mode === 'betterme' ? 'text-base font-normal [&>br]:block [&>br]:content-[""] [&>br]:mb-3 [&>b]:font-semibold text-[#171717b8] [&>b]:text-dark' : 'text-xl font-normal [&>br]:block [&>br]:content-[""] [&>br]:mb-3 [&>b]:font-semibold text-[#171717b8] [&>b]:text-dark'}`}
          dangerouslySetInnerHTML={{ __html: props.content.paragraph.text }}
        ></p>
      )}
      {props?.content?.smallSpan && (
        <div className="mt-4 flex items-start">
          <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-4 shrink-0"
          >
            <path
              d="M13 7V5C13 2.2 10.8 0 8 0C5.2 0 3 2.2 3 5V7C1.3 7 0 8.3 0 10V17C0 18.7 1.3 20 3 20H13C14.7 20 16 18.7 16 17V10C16 8.3 14.7 7 13 7ZM5 5C5 3.3 6.3 2 8 2C9.7 2 11 3.3 11 5V7H5V5Z"
              fill="#727272"
            />
          </svg>

          <p className="text-base2 opacity-75 font-medium">{props?.content?.smallSpan.text}</p>
        </div>
      )}
      {props?.content?.bageAccount && (
        <div className="-mt-4 mb-5 flex relative items-center justify-center gap-x-12">
          <div className="absolute block z-0 w-[24px] h-[17px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bage-animation-arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="17"
              viewBox="0 0 22 28"
              fill="none"
              className="absolute block right-0 -translate-y-1/2 top-1/2 animation-arrows-1"
            >
              <path
                d="M21.6742 13.1539L10.2748 0.487893C10.0316 0.219374 9.69217 0.0673828 9.33246 0.0673828H1.73287C1.23383 0.0673828 0.780384 0.361234 0.575195 0.817209C0.37254 1.27572 0.456135 1.81022 0.790517 2.18007L11.4274 14L0.790517 25.8173C0.456135 26.1897 0.370007 26.7242 0.575195 27.1802C0.780384 27.6387 1.23383 27.9325 1.73287 27.9325H9.33246C9.69217 27.9325 10.0316 27.778 10.2748 27.5146L21.6742 14.8486C22.1074 14.3673 22.1074 13.6326 21.6742 13.1539Z"
                fill="#1BDA72"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="17"
              viewBox="0 0 22 28"
              fill="none"
              className="absolute block left-0 -translate-y-1/2 top-1/2 animation-arrows-2"
            >
              <path
                d="M21.6742 13.1539L10.2748 0.487893C10.0316 0.219374 9.69217 0.0673828 9.33246 0.0673828H1.73287C1.23383 0.0673828 0.780384 0.361234 0.575195 0.817209C0.37254 1.27572 0.456135 1.81022 0.790517 2.18007L11.4274 14L0.790517 25.8173C0.456135 26.1897 0.370007 26.7242 0.575195 27.1802C0.780384 27.6387 1.23383 27.9325 1.73287 27.9325H9.33246C9.69217 27.9325 10.0316 27.778 10.2748 27.5146L21.6742 14.8486C22.1074 14.3673 22.1074 13.6326 21.6742 13.1539Z"
                fill="#1BDA72"
              />
            </svg>
          </div>
          <div className="w-[130px] bg-green p-1 z-10 rounded-3xl flex items-center justify-center">
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="shrink-0 mr-1"
            >
              <circle cx="5.5" cy="5.5" r="5.5" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.13067 3.7873C8.28978 3.9464 8.28978 4.20435 8.13067 4.36346L5.27882 7.21531C5.11972 7.37441 4.86176 7.37441 4.70266 7.21531L2.86933 5.38198C2.71022 5.22287 2.71022 4.96492 2.86933 4.80581C3.02843 4.64671 3.28639 4.64671 3.44549 4.80581L4.99074 6.35107L7.55451 3.7873C7.71361 3.62819 7.97157 3.62819 8.13067 3.7873Z"
                fill="#13D16A"
              />
            </svg>

            <p className="text-base text-white font-bold">ACCOUNT</p>
          </div>
          <div className="w-[130px] bg-[#929B9E] p-1 z-10 rounded-3xl flex items-center justify-center">
            <p className="text-base text-white font-bold">✨YOUR PLAN</p>
          </div>
        </div>
      )}
      {props?.content?.reviews && (
        <Swiper
          modules={[Autoplay, Pagination]}
          className="!overflow-visible mt-10"
          spaceBetween={16}
          loop={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {props?.content?.reviews.map((review, index) => {
            return (
              <SwiperSlide key={index}>
                <Review
                  key={review.title + review.name}
                  title={review.title}
                  src={review.src}
                  srcWebp={review.srcWebp}
                  quote={review.quote}
                  stars={review.stars}
                  name={review?.name}
                  mainTitle={''}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
      {props?.content?.testimonials && props.mode === 'betterme' && (
        <>
          {props.mode !== 'betterme' ? (
            <h3 className="text-base font-extrabold my-5">{t('Trusted by 98,425 users!')}</h3>
          ) : (
            <p className="mb-2"></p>
          )}
          <Swiper
            modules={[Autoplay, Pagination]}
            className="!overflow-visible testimonials-swiper"
            spaceBetween={16}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {props?.content?.testimonials.map(
              (review: TestimonialsType, index: React.Key | null | undefined) => {
                return (
                  <SwiperSlide key={index}>
                    <TestimonialsBetterme
                      key={index}
                      title={review.title}
                      src={review.src}
                      srcWebp={review.srcWebp}
                      quote={review.quote}
                      name={review?.name}
                      mode={review?.mode}
                    />
                  </SwiperSlide>
                )
              },
            )}
          </Swiper>
        </>
      )}
      {props?.content?.testimonials && props.mode !== 'betterme' && (
        <>
          <h3 className="text-base font-extrabold mt-3">{t('Trusted by 98,425 users!')}</h3>

          <Swiper
            modules={[Autoplay, Pagination]}
            className="!overflow-visible testimonials-swiper"
            spaceBetween={16}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {props?.content?.testimonials.map(
              (review: TestimonialsType, index: React.Key | null | undefined) => {
                return (
                  <SwiperSlide key={index}>
                    <Testimonials
                      key={index}
                      title={review.title}
                      src={review.src}
                      srcWebp={review.srcWebp}
                      quote={review.quote}
                      name={review?.name}
                      mode={review?.mode}
                    />
                  </SwiperSlide>
                )
              },
            )}
          </Swiper>
        </>
      )}
    </>
  )
}
