import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers, LifestyleActiveType } from '@/types/types'
import Lottie from 'lottie-react'
import React, { useEffect, useRef, useState } from 'react'
import animationSwipe from '@/assets/images/swipe.json'

interface LifestyleActiveProps {
  attributes: [LifestyleActiveType]
  onAnswer: (answer: Answer, step?: number) => void
  name: string
  isActive: boolean
  answers: Answers
}

export function LifestyleActive(props: LifestyleActiveProps) {
  const [showLottie, setShowLottie] = useState(true)
  const lottieRef: any = useRef()
  const [value, setValue] = useState(props.attributes[0])
  const [animationData, setAnimationData] = useState([''])
  async function fetchAnimationData() {
    try {
      const animationDataArray = []
      for (const item of props.attributes) {
        const response = await fetch(item.src)
        const data = await response.json()
        animationDataArray.push(data)
      }
      return animationDataArray
    } catch (error) {
      console.error('Error fetching animation data:', error)
    }
  }
  useEffect(() => {
    fetchAnimationData().then((res) => {
      if (res) {
        setAnimationData(res)
      }
    })
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      // eslint-disable-next-line array-callback-return
      props.attributes.map((item) => {
        if (item.name === choosedValue) {
          setValue(item)
        }
      })
    }
  }, [])
  useEffect(() => {
    if (props.isActive) {
      lottieRef.current.play()
      setTimeout(() => {
        lottieRef.current.pause()
        setShowLottie(false)
      }, 5000)
      props.onAnswer({ [props.name]: [props.attributes[0].name + ''] })
    }
  }, [props.isActive])
  useEffect(() => {
    if (props.isActive) {
      props.onAnswer({ [props.name]: [value.name + ''] })
    }
  }, [value])
  const touchMoveFun = (e: any) => {
    const swipeVal = +((e.changedTouches[0].pageX * 3) / window.innerWidth - 0.5).toFixed(0)
    setValue(props.attributes[Math.abs(swipeVal)])
  }
  return (
    <div onTouchMove={touchMoveFun} onTouchStart={touchMoveFun} className="-mt-4">
      <div className="images relative pointer-events-none">
        {props.attributes.map(
          (item, index) =>
            item.name === value.name && (
              <div key={index} className="sm:mb-20 mb-6">
                <div className="mx-auto w-44 h-44 rounded-full border-borderInput border flex items-center justify-center">
                  <Lottie className="max-w-32 w-auto" animationData={animationData[index]} />
                </div>
                <h2 className="text-base font-extrabold text-center m-0 p-0 uppercase mt-5">
                  {item.title}
                </h2>
                <p className="font-semibold text-dark opacity-65 text-center mt-2">{item.text}</p>
              </div>
            ),
        )}
      </div>
      <div className="relative h-4 bg-progress rounded-lg px-20">
        <input
          className="input-range relative z-20"
          type="range"
          value={value.index}
          step={1}
          max={2}
          onChange={(e) => setValue(props.attributes[+e.target.value])}
        />
        <div className="range-dots-style w-full flex items-center justify-between absolute px-20 left-0 top-0 h-full">
          {props.attributes.map((_, index) => (
            <span
              key={index}
              className="block w-3 h-3 mx-1 rounded-full bg-dots border-2 border-white z-10"
            ></span>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-between mt-4 px-16 mx-2 relative z-10">
        {props.attributes.map((item, index) => (
          <p className="text-xl12 text-black font-medium lifestyle " key={index}>
            {item.name === 'medium' ? 'med' : item.name}
          </p>
        ))}
      </div>
      <div
        className={
          (showLottie ? 'opacity-100' : 'opacity-0') +
          ' absolute -bottom-16 w-20 left-1/2 -translate-x-1/2 transition-all'
        }
      >
        <Lottie lottieRef={lottieRef} animationData={animationSwipe} loop={true} autoplay={false} />
      </div>
    </div>
  )
}
