import { Calendar } from '@/components/Calendar/Calendar'
import React, { useEffect, useState } from 'react'
import { Answer, Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface DatePickerProps {
  onAnswer: (answer: Answer) => void
  name: string
  answers: Answers
}
export const DatePickerBetterme = (props: DatePickerProps) => {
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      setDate(new Date(choosedValue as string))
    } else {
      const currentDate = new Date()

      currentDate.setMonth(currentDate.getMonth() + 1)
      setDate(currentDate)
    }
  }, [])

  useEffect(() => {
    if (date) {
      props.onAnswer({
        [props.name]: [date.toDateString()],
      })
      setIsOpen(false)
    } else {
      props.onAnswer({
        [props.name]: [''],
      })
    }
  }, [date])

  return (
    <>
      <button
        className="border border-[#17171777] relative w-full text-left px-4 py-2 rounded-r12"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="block text-xs2 opacity-75">Select Date</span>
        <input value={date?.toDateString()} readOnly={true} className="font-girloy" />
        <svg
          viewBox="0 0 24 24"
          width="1em"
          height="1em"
          data-icon="CalendarOutlineIcon"
          className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeM__ckFGG svg-icon-module_colorModeDefault__tEprC info-page-calendar-wall-pilates-brand_icon__zDGZ0 absolute right-4 top-1/2 -translate-y-1/2 w-6 opacity-75 h-6"
        >
          <path
            fillOpacity="0.72"
            fillRule="evenodd"
            d="M7 1.5a1 1 0 0 1 1 1v.508C8.593 3 9.234 3 9.927 3h4.146c.693 0 1.334 0 1.927.008V2.5a1 1 0 1 1 2 0v.597c.18.017.355.036.522.058 1.2.162 2.211.507 3.014 1.31.802.802 1.147 1.813 1.309 3.013C23 8.634 23 10.103 23 11.927v2.146c0 1.824 0 3.293-.155 4.45-.162 1.2-.507 2.21-1.31 3.012-.802.803-1.813 1.148-3.013 1.31-1.156.155-2.625.155-4.449.155H9.927c-1.824 0-3.293 0-4.45-.155-1.2-.162-2.21-.507-3.013-1.31-.802-.802-1.147-1.813-1.309-3.013C1 17.366 1 15.897 1 14.073v-2.146c0-1.824 0-3.293.155-4.45.162-1.2.507-2.21 1.31-3.013.802-.802 1.813-1.147 3.013-1.309.167-.022.341-.041.522-.058V2.5a1 1 0 0 1 1-1ZM5.744 5.138c-.978.131-1.496.372-1.865.74-.37.37-.61.888-.741 1.866A9.763 9.763 0 0 0 3.107 8h17.786a9.896 9.896 0 0 0-.03-.256c-.132-.978-.373-1.496-.742-1.865-.369-.37-.887-.61-1.865-.741C17.249 5.002 15.914 5 14 5h-4c-1.914 0-3.249.002-4.256.138ZM3 12c0-.75 0-1.412.009-2H20.99c.009.588.009 1.25.009 2v2c0 1.914-.002 3.249-.137 4.256-.132.978-.373 1.496-.742 1.865-.369.37-.887.61-1.865.742-1.007.135-2.342.137-4.256.137h-4c-1.914 0-3.249-.002-4.256-.137-.978-.132-1.496-.373-1.865-.742-.37-.369-.61-.887-.741-1.865C3.002 17.249 3 15.914 3 14v-2Zm14 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-4-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-6-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          disabled={(date) => date < new Date()}
          showOutsideDays={false}
        />
      )}
      <div className="mt-2 flex items-start">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="info-page-calendar-wall-pilates-brand_lockIcon__pveXU w-4 mr-2 opacity-75 min-w-4"
        >
          <path
            d="M13 7.66667C13 6.93133 12.4394 6.33333 11.75 6.33333H11.125V4.33333C11.125 2.49533 9.72312 1 8 1C6.27688 1 4.875 2.49533 4.875 4.33333V6.33333H4.25C3.56062 6.33333 3 6.93133 3 7.66667V13C3 13.7353 3.56062 14.3333 4.25 14.3333H11.75C12.4394 14.3333 13 13.7353 13 13V7.66667ZM6.125 4.33333C6.125 3.23067 6.96625 2.33333 8 2.33333C9.03375 2.33333 9.875 3.23067 9.875 4.33333V6.33333H6.125V4.33333Z"
            fill="currentColor"
          ></path>
        </svg>
        <p className=" text-xs2 opacity-75 font-medium">
          Your data will be processed in accordance with our{' '}
          <a href="/privacy-policy" className="underline">
            Privacy Policy
          </a>
        </p>
      </div>
    </>
  )
}
