import React, { useEffect, useState } from 'react'
import { Answer, Answers, InputType } from '@/types/types'
import { useTranslation } from 'react-i18next'
import height from '@/assets/images/years.svg'
import { getValue } from '@/helpers/getObjectValue'

interface InputProps {
  attributes: InputType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  error: string
  step: number
  disabled?: boolean
  answers: Answers
}

export default function InputBetterme(props: InputProps) {
  const { t } = useTranslation()
  const [showError, setShowError] = useState('')
  const [updatedPlaceholder, setUpdatedPlaceholder] = useState('')
  const [inputValue, setInputValue] = useState('')
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let isValid = e.target.checkValidity()
    let value = e.target.value
    if (props.attributes.type === 'email') {
      const emailRegex = /^(?!.*__)[a-zA-Z0-9_.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      isValid = emailRegex.test(e.target.value)
    }
    if (props.attributes.type === 'number' && value) {
      if (props.attributes.min) {
        const minLength = props.attributes.min.toString().length
        if (value.length >= minLength && Number(value) < props.attributes.min) {
          value = props.attributes.min.toString()
        }
      }

      if (value && props.attributes.max && props.attributes.max < Number(value)) {
        value = props.attributes.max.toString()
      }
    }
    if (isValid) {
      props.onAnswer({ [props.name]: [e.target.value] })
      setShowError('')
    } else {
      if (props.attributes.type === 'email') {
        props.onAnswer({ [props.name]: [] })
        setShowError(t('invalidEmail'))
      }
    }
    setInputValue(value)
  }

  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue && typeof choosedValue === 'string') {
      setShowError('')
      setInputValue(choosedValue)
    }
    if (props.name === 'your-age') {
      const placeholder =
        localStorage.getItem('placeholderAge') !== null
          ? localStorage.getItem('placeholderAge')
          : ''
      setUpdatedPlaceholder(placeholder ?? '')
    }
  }, [])

  useEffect(() => {
    if (props.error) {
      setShowError(props.error)
    }
  }, [props.error])
  return (
    <div>
      {props.attributes.label && (
        <label className="!font-semibold text-xs2 opacity-65">{props.attributes.label}</label>
      )}
      <div
        className={`${props.attributes.type === 'email' ? '' : 'border-b border-dark gap-2 p-2  font-girloy'} flex items-center `}
      >
        <input
          value={inputValue}
          type={props.attributes.type}
          inputMode={props.attributes.inputMode === 'numberic' ? 'numeric' : 'text'}
          onChange={(e) => handleChangeInput(e)}
          placeholder={
            updatedPlaceholder.length ? updatedPlaceholder : props.attributes.placeholder
          }
          minLength={props.attributes.minLength}
          maxLength={props.attributes.maxLength}
          min={props.attributes.min}
          max={props.attributes.max}
          step={props.attributes.step}
          required={props.attributes.required}
          className={`${showError ? '!border-mark' : ''} ${props.attributes.type === 'email' ? 'border border-[#17171775] font-base1 rounded-r12 p-4 block w-full' : 'block w-full text-xl45 font-semibold text-center outline-none placeholder:text-dark placeholder:opacity-35'}`}
        />
        {props.attributes.type !== 'email' && props.attributes.type !== 'text' && (
          <span className="text-xl45 font-semibold block">
            {updatedPlaceholder.length ? 'years' : props.attributes.placeholder}
          </span>
        )}
      </div>
      {showError && <span className="text-mark block mt-2 text-xs2 ">{showError} error</span>}
      {props.attributes.tooltip && (
        <div
          className={`${inputValue.length === 0 ? 'opacity-0' : 'opacity-100'} transition-all p-6 border border-[#17171714] bg-goal mt-4 rounded-r16`}
        >
          <div className="flex items-center justify-start opacity-75">
            <img
              className="min-w-6 max-w-6 mr-3"
              src={height}
              width="24"
              height="24"
              alt="Height icon"
            />
            <h6 className="font-medium text-md leading-tight">
              We ask your age to personalize your plan
            </h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            Older people are found to have a higher body fat percentage than younger people with the
            same BMI.
          </p>
        </div>
      )}
    </div>
  )
}
