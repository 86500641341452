import React from 'react'

interface TooltipBettermeProps {
  icon: string
  title: string
  description: string
  type: string
}
export function TooltipBetterme(props: TooltipBettermeProps) {
  const { title, description, icon, type } = props
  return (
    <div>
      <div
        className={`${type === 'danger' ? 'bg-danger border-dangerStorke' : ''} ${type === 'warning' ? 'bg-warning border-warningStroke' : ''} ${type === 'good' ? 'bg-good border-goodStroke' : ''} p-4 border mt-4 rounded-r16`}
      >
        <div className="flex items-center">
          <img className="mr-2 min-w-6" src={icon} width="24" height="24" alt="Height icon" />
          <h6 className="text-md font-medium">{title}</h6>
        </div>
        <p className="mt-2 text-md leading-snug">{description}</p>
      </div>
    </div>
  )
}
