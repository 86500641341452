import { formattedPrice } from '@/helpers/formatPrice'
import React from 'react'
import { PaymentPlan } from '@/types/types'
import { useTranslation } from 'react-i18next'

export default function Subtotal(props: {
  plan: PaymentPlan
  isDiscount: boolean
  isFreeTrial: boolean
}) {
  const { t } = useTranslation()
  const originalPrice = props.isFreeTrial ? 1 : props.plan.originalPrice
  const savedMoney = props.plan.originalPrice - props.plan.discountedPrice
  const totalPrice = props.isFreeTrial ? 1 : props.plan.discountedPrice
  const percents = props.isDiscount ? '71%' : '61%'
  return (
    <>
      {/* <div className="transition-all rounded-r12 shadow-green w-16 h-16 mx-auto mb-5 cursor-pointer active:scale-95 duration-300 relative"> */}
      {/*   <div className="w-16 h-16 bg-white rounded-r12 border border-borderInput shadow-back p-4"> */}
      {/*     <span className="block h-2 w-7 bg-[#69E07F] rounded-r4 ml-1 mt-px"></span> */}
      {/*     <span className="block h-2 w-5 bg-[#69E07F] rounded-r4 ml-0.5 mt-0.5"></span> */}
      {/*     <span className="block h-2 w-2 bg-[#69E07F] rounded-r4  mt-0.5"></span> */}
      {/*   </div> */}
      {/* </div> */}
      <div className="flex justify-between">
        <span>Personalized Weight Loss Plan</span>
        <span>${formattedPrice(originalPrice)}</span>
      </div>
      {!props.isFreeTrial && (
        <div className="flex justify-between mt-1">
          <span>
            {percents} {t('Introductory offer discount')}
          </span>
          <span className="text-mark font-semibold">-${formattedPrice(savedMoney)}</span>
        </div>
      )}
      <div className="flex justify-between border-t border-primary mt-2 pt-2 mb-6">
        <span className="font-bold text-black text-xl">{t('Total')}</span>
        <span className="text-right">
          <span className="block font-bold text-black text-xl">${formattedPrice(totalPrice)}</span>
          {!props.isFreeTrial && (
            <span className="block mt-2 text-md text-mark font-semibold">
              🔥{t('You just saved')} ${formattedPrice(savedMoney)} ({percents} OFF)
            </span>
          )}
        </span>
      </div>
    </>
  )
}
