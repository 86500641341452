import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { Appearance, Stripe, StripeError } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import CheckoutForm from '@/components/StripeCheckout/CheckoutForm'
import { Answers, PaymentPlan, Plan, Subscription } from '@/types/types'
import { formatPlan } from '@/helpers/formatPlan'
import {
  StripeRequestHeader,
  checkExistSubscription,
  checkIfCustomerExist,
  checkPaymentIntent,
  createScheduleSubscriptionForCustomer,
  createStripeCustomer,
  createSubscriptionForCustomer,
  deleteSubscription,
} from '@/helpers/stripeHelpers'
import { getValue } from '@/helpers/getObjectValue'
import { Button } from '@/components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Notification from '@/components/Notification/Notification'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { useTranslation } from 'react-i18next'
import StripeErrorModal from './StripeErrorModal'
import { trackPayment, trackPaymentErrors } from '@/helpers/facebookPixelEvents'

interface StripeCheckoutProps {
  plan: Plan
  reservePlan: Plan
  isDiscount: boolean
  isFreeTrial: boolean
  searchParams?: URLSearchParams
  handleIsLoading: (loading: boolean) => void
}
const stripePublishKeyMain = process.env.REACT_APP_STRIPE_PUBLISH_KEY_MAIN || ''
const stripePublishKeyReserve = process.env.REACT_APP_STRIPE_PUBLISH_KEY_RESERVE || ''

const stripeSecretKeyMain = process.env.REACT_APP_STRIPE_SECRET_KEY_MAIN || ''
const stripeSecretKeyReserve = process.env.REACT_APP_STRIPE_SECRET_KEY_RESERVE || ''

export default function StripeCheckout(props: StripeCheckoutProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const storedData = localStorage.getItem('quizResult')
  const customerPortalId = localStorage.getItem('customerPortalId') as string
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const [clientSecretKey, setClientSecretKey] = useState('')
  const [stripePublishKey, setStripePublishKey] = useState(stripePublishKeyMain)
  const [error, setError] = useState<StripeError>()
  const [customerHasSubscription, setCustomerHasSubscription] = useState(false)
  const [stripePromise, setStripePromise] = useState<Stripe | null | boolean>(false)
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false)

  const [plan, setPlan] = useState<PaymentPlan | undefined>(
    props.plan ? formatPlan(props.plan, props.isDiscount, props.isFreeTrial) : undefined,
  )

  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  const userEmail = getValue('email', answers) as string
  const userName = (getValue('your-name', answers) as string) ?? ('Without name' as string)
  const customer = { name: userName, email: userEmail, customerPortalID: customerPortalId }
  const subscriptionString = localStorage.getItem('subscriptionInfo')
  const [subscription, setSubscription] = useState<Subscription>(
    subscriptionString && subscriptionString !== ''
      ? JSON.parse(subscriptionString)
      : { id: '', current_period_end: '', current_period_start: '', plan },
  )

  const init = () => {
    loadStripe(stripePublishKey, { locale: 'en' })
      .then((res) => {
        if (res) {
          trackPayment('stripe:load', 'success')
          setStripePromise(res)
        } else {
          trackPaymentErrors('stripe:load', 'default load error')
        }
      })
      .catch((error) => {
        trackPaymentErrors('stripe:load:catch', error)
      })
  }

  useEffect(() => {
    StripeRequestHeader.token = stripeSecretKeyMain

    if (props.searchParams) {
      const paymentIntent = props.searchParams.get('payment_intent')
      if (paymentIntent) {
        checkPaymentIntent(paymentIntent).then((data) => {
          if (data.status === 'succeeded') {
            if (props.plan.nextProductStripeID && plan) {
              createScheduleSubscriptionForCustomer(subscription, plan).then(() => {
                setSuccessPayment(data, navigate)
              })
            } else {
              setSuccessPayment(data, navigate)
            }
          } else {
            setIsPaymentFailed(true)
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    if (!clientSecretKey) {
      checkIfCustomerExist(customer).then((data) => {
        console.log(data)
        if (data.data.length) {
          const userID = data.data[0].id
          checkExistSubscription(userID).then((data) => {
            const subscriptions = data.data
            if (subscriptions.length) {
              if (subscriptions.filter((el: any) => el?.status === 'active') <= 0) {
                deleteSubscription(subscriptions[0].id).then(() => {
                  createSubscription(userID)
                })
              } else {
                setCustomerHasSubscription(true)
                props.handleIsLoading(false)
              }
            } else {
              createSubscription(userID)
            }
          })
        } else {
          createStripeCustomer(customer).then((data) => {
            createSubscription(data.id)
          })
        }
      })
    }
  }, [clientSecretKey])

  useEffect(() => {
    if (!stripePromise) {
      init()
    }
  }, [stripePromise, stripePublishKey])

  const createSubscription = (userID: string) => {
    if (plan) {
      createSubscriptionForCustomer(userID, plan).then((data) => {
        console.log(data)
        const subscriptionObj = {
          id: data.id,
          current_period_end: data.current_period_end,
          current_period_start: data.current_period_start,
          plan: data.plan.id,
        }
        setSubscription(subscriptionObj)
        localStorage.setItem('subscriptionInfo', JSON.stringify(subscriptionObj))
        setClientSecretKey(data.latest_invoice.payment_intent.client_secret)
      })
    }
  }

  const handleResetPaymentElement = () => {
    setClientSecretKey('')
  }

  const handleChangeStripeKey = () => {
    props.handleIsLoading(true)
    setPlan(formatPlan(props.reservePlan, props.isDiscount, props.isFreeTrial))
    setStripePromise(false)
    setClientSecretKey('')
    setStripePublishKey(stripePublishKeyReserve)

    StripeRequestHeader.token = stripeSecretKeyReserve
  }

  const handleCloseErrorModal = () => {
    setIsOpenErrorModal(false)

    if (error?.decline_code === 'do_not_honor') {
      handleChangeStripeKey()
    }
  }

  const handleError = (error: StripeError) => {
    setError(error)
    trackPaymentErrors('stripe:error', error)

    if (error.decline_code === 'insufficient_funds' || error.decline_code === 'do_not_honor') {
      setIsOpenErrorModal(true)

      return
    }

    setIsPaymentFailed(true)
  }

  const handleResetNotification = () => {
    setIsPaymentFailed(false)
  }

  const appearance: Appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#13D16A',
      tabIconSelectedColor: '#6d6e78',
      colorBackground: '#FAFAFA',
    },
    rules: {
      '.Input': {
        border: '1px solid #E9E9E9',
        borderRadius: '4px',
        backgroundColor: '#fff',
      },
      '.Tab': {
        color: '#6d6e78',
        border: '1px solid #E9E9E9',
        borderRadius: '4px',
        backgroundColor: '#fff',
      },
      '.Tab:active': {
        border: '1px solid #13D16A',
        boxShadow:
          '0px 2px 2px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 2px #13D16A',
        transform: 'scale(0.92) !important',
        scale: '0.92',
        backgroundColor: '#fff',
        transition: '0.15s',
      },
      '.Block': {
        transition: 'none',
        border: 'none',
        boxShadow: 'none',
      },
      '.Tab--selected': {
        color: '#6d6e78',
        border: '1px solid #13D16A',
        backgroundColor: '#fff',
        boxShadow:
          '0px 2px 2px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 2px #13D16A',
      },
      '.Tab--selected:focus': {
        backgroundColor: '#fff',
        boxShadow:
          '0px 2px 2px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 2px #13D16A',
      },
      '.Tab--selected:hover': {
        color: '#6d6e78',
      },
      '.Tab:focus': {
        backgroundColor: '#fff',
        boxShadow: '',
      },
    },
  }

  return (
    <>
      {stripePromise !== false &&
        stripePromise !== null &&
        clientSecretKey &&
        plan &&
        !customerHasSubscription && (
          <Elements
            stripe={stripePromise as Stripe}
            options={{
              clientSecret: clientSecretKey,
              appearance,
            }}
          >
            <CheckoutForm
              handleError={handleError}
              isDiscount={props.isDiscount}
              isFreeTrial={props.isFreeTrial}
              handleResetPaymentElement={handleResetPaymentElement}
              handleIsLoading={(loading: boolean) => props.handleIsLoading(loading)}
              plan={plan}
              subscription={subscription}
              navigate={navigate}
            />
            <Notification
              state={isPaymentFailed}
              handleResetNotification={handleResetNotification}
              className="error fixed bottom-6 max-w-content w-full px-4 left-1/2 -translate-x-1/2 transition-al duration-300"
            >
              <span className="w-full py-4 px-6 text-center flex items-center justify-center bg-mark text-white rounded-xl">
                {error?.message || t('The payment failed')}
              </span>
            </Notification>
          </Elements>
        )}

      {customerHasSubscription && (
        <div className="w-full">
          <h2 className="text-center text-primary">{t('You already have a subscription')}</h2>
          <div className="mt-4 fixed bottom-4 max-w-content w-full container left-1/2 -translate-x-1/2 z-30">
            <Button onClick={() => setSuccessPayment({}, navigate)}>{t('Create account')}</Button>
          </div>
        </div>
      )}

      <StripeErrorModal isOpen={isOpenErrorModal} error={error} onClose={handleCloseErrorModal} />
    </>
  )
}
