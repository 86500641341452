import React, { useEffect, useState } from 'react'
import danger from '@/assets/images/dangerBet.svg'
import warning from '@/assets/images/warningBet.svg'
import good from '@/assets/images/goodBet.svg'
import { Answers } from '@/types/types'
import { useTranslation } from 'react-i18next'
import { getValue } from '@/helpers/getObjectValue'

interface BMITooltipProps {
  bmi: number
  percent?: boolean
  answers: Answers
  unit: string
  betterme?: boolean
}
export function BMITooltipBetterme(props: BMITooltipProps) {
  const [low, setLow] = useState(0)
  const [hight, setHight] = useState(0)
  const [unit, setUnit] = useState('kg')
  const { t } = useTranslation()
  useEffect(() => {
    if (props.answers) {
      const heightProps = getValue('human-height', props.answers)
      if (heightProps) {
        const tall =
          heightProps && heightProps[1] === 'FT'
            ? (+heightProps[0] * 30.48) / 100
            : +heightProps[0] / 100
        if (props.unit === 'lbs') {
          setHight(25 * (tall * tall) * 2.2)
          setLow(18.5 * (tall * tall) * 2.2)
          setUnit('lbs')
        } else {
          setHight(25 * (tall * tall))
          setLow(18.5 * (tall * tall))
          setUnit('kg')
        }
      }
    }
  }, [props.bmi])
  return (
    <div>
      {props.bmi >= 6 && props.bmi < 18.5 && props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'}  border border-dangerStroke bg-danger  rounded-r16`}
        >
          <div className="flex items-center">
            <img className="mr-4 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <h6 className="text-md leading-5 font-medium">{t('Uh-oh! Low weight alert!')}</h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            {t('A normal weight range for your height is between')} {Math.ceil(low)} {unit}{' '}
            {t('and')} {Math.round(hight)} {unit}. {t('Any weight below')} {Math.ceil(low)} {unit}{' '}
            {t('is classified as underweight and is not recommended by World Health Organization.')}
          </p>
        </div>
      )}
      {props.bmi > 26.5 && props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'}  border border-dangerStroke bg-danger  rounded-r16`}
        >
          <div className="flex items-center">
            <img className="mr-4 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <h6 className="text-md leading-5 font-medium">{t('Uh-oh! High weight alert!')}</h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            {t('A normal weight range for your height is between')} {Math.ceil(low)} {unit}{' '}
            {t('and')} {Math.round(hight)} {unit}. {t('Any weight more')} {Math.ceil(hight)} {unit}{' '}
            {t('is classified as overweight and is not recommended by World Health Organization.')}
          </p>
        </div>
      )}
      {props.bmi >= 6 && props.bmi < 18.5 && !props?.percent && (
        <div className="p-6 mt-4 border border-dangerStroke bg-danger  rounded-r16">
          <div className="flex items-center">
            <img className="mr-2 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <h6 className="text-md leading-5">
              {t('Your BMI is')} {props.bmi.toFixed(1)} which is considered
              <strong className="font-bold"> underweight</strong>
            </h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            You have some work ahead of you, but it&apos;s great that you&apos;re taking this first
            step. We&apos;ll use your BMI to create a program just for you.
          </p>
        </div>
      )}
      {props.bmi >= 18.5 && props.bmi < 25 && !props?.percent && (
        <div className="p-6 mt-4 border border-goodStroke bg-good  rounded-r16">
          <div className="flex items-center">
            <img className="mr-2 min-w-6" src={good} width="24" height="24" alt="Height icon" />
            <h6 className="text-md leading-5">
              {t('Your BMI is')} {props.bmi.toFixed(1)} – which is considered
              <strong className="font-bold"> normal</strong>
            </h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            You&apos;re starting from a great place! Now we&apos;ll use your BMI to create a program
            tailored to your needs.
          </p>
        </div>
      )}
      {props.bmi >= 25 && props.bmi < 30 && !props?.percent && (
        <div className="p-6 mt-4  border border-warningStroke bg-warning  rounded-r16">
          <div className="flex items-center">
            <img className="mr-2 min-w-6" src={warning} width="24" height="24" alt="Height icon" />
            <h6 className="text-md leading-5">
              {t('Your BMI is')} {props.bmi.toFixed(1)} which is considered
              <strong className="font-bold"> overweight</strong>
            </h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            You have some work ahead of you, but it’s great that you&apos;re taking this first step.
            We’ll use your BMI to create a weight loss program just for you.
          </p>
        </div>
      )}
      {props.bmi >= 30 && !props?.percent && (
        <div className="p-6 mt-4 border border-dangerStroke bg-danger  rounded-r16">
          <div className="flex items-center">
            <img className="mr-2 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <h6 className="text-md leading-5">
              {t('Your BMI is')} {props.bmi.toFixed(1)} which is considered
              <strong className="font-bold"> obese</strong>
            </h6>
          </div>
          <p className="mt-2 text-md leading-snug tracking-[-0.03em]">
            There&apos;s a lot you could gain by losing a little weight. We&apos;ll use your BMI to
            create the weight loss program you need.
          </p>
        </div>
      )}
    </div>
  )
}
