import React, { ChangeEvent, useEffect, useRef } from 'react'
import { Answer, Answers, CheckboxCardType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface CheckboxCardProps {
  attributes: CheckboxCardType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
  answers: Answers
}

export default function CheckboxCard(props: CheckboxCardProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parent = e.target.closest('.steps') ?? document
    const reset: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[data-reset]')
    const allCheck: NodeListOf<HTMLInputElement> =
      parent.querySelectorAll('input[data-choosed-all]')
    if (e.target.checked && !e.target.dataset.reset && !e.target.dataset.choosedAll) {
      props.onAnswer({ [props.name]: [e.target.value] })
      reset.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
      allCheck.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    } else if (!e.target.checked && !e.target.dataset.reset && !e.target.dataset.choosedAll) {
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
      allCheck.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    }
    if (e.target.checked && e.target.dataset.reset) {
      const targetName = e.target.getAttribute('name')
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = element.getAttribute('name') === targetName && e.target === element
      })
      props.onAnswer({ [props.name]: [e.target.value] })
    } else if (!e.target.checked && e.target.dataset.reset) {
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
    }
    if (e.target.dataset.choosedAll && e.target.checked) {
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element && element.checked === false) {
          props.onAnswer({ [props.name]: [element.value] })
          element.checked = true
        }
      })
      e.target.checked = true
      props.onAnswer({ [props.name]: [e.target.value] })
    } else if (e.target.dataset.choosedAll && !e.target.checked) {
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = false
      })
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
      e.target.checked = false
    }
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    const inputs = document.querySelectorAll('input')
    if (choosedValue && typeof choosedValue === 'string') {
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
    if (choosedValue && typeof choosedValue === 'object') {
      choosedValue.forEach((element) => {
        inputs.forEach((input) => {
          if (input.value === element) {
            input.checked = true
          }
        })
      })
    }
  }, [])
  return (
    <div>
      <label
        className={`
          bg-white items-center checkbox font-semibold border relative p-1 border-[#EFF1F4] m-0 rounded-xl  min-h-[74px] cursor-pointer  transition duration-300 font-girloy  after:absolute after:content=[''] after:bottom-6 after:w-6 after:h-6 pt-0  after:border after:border-[#17171775] after:bg-transparent after:right-4 after:rounded-r4  active:scale-95
         ${
           checkbox.current?.checked
             ? ' !bg-gray   border-gray after:!bg-dark after:border-dark border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
             : ''
         }`}
      >
        {props.attributes?.img && (
          <span className="flex items-center w-full justify-center">
            <img
              className="w-auto max-w-full object-cover h-[138px] w-auto"
              width={24}
              height={24}
              alt="Checkbox img"
              src={props.attributes?.img}
            />
          </span>
        )}
        <span className="h-16 flex items-center leading-normal block p-3 pr-10 bg-white rounded-r12 text-base text-semibold">
          {props.attributes.text}
        </span>
        <input
          type="checkbox"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          name={props.name}
          value={props.attributes.hideText ? props.attributes.hideText : props.attributes.text}
          className="hidden"
          ref={checkbox}
          data-reset={props.attributes.resetCheckbox}
          data-choosed-all={props.attributes.allCheckbox}
        />
      </label>
    </div>
  )
}
