import React, { useEffect, useState } from 'react'
import person from '@/assets/images/person.svg'
import lifestyle from '@/assets/images/lifestyle.svg'
import level from '@/assets/images/level.svg'
import { Answer, Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import Image from 'react-image-webp'
import { BMIBetterme } from '@/components/QuestionsUI/BMIBetterme'

interface ProfileProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}
export function ProfileBetterme(props: ProfileProps) {
  const { t } = useTranslation()
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const fitnessLevel = getValue('how-often-exercise', props.answers)
  const heightProps = getValue('human-height', props.answers)
  const weightProps = getValue('current-weight', props.answers)
  const [bmi, setBMI] = useState(15)
  useEffect(() => {
    if (props.active) {
      if (heightProps && weightProps) {
        const tall =
          heightProps && heightProps[1] === 'FT' ? +heightProps[0] * 30.48 : +heightProps[0]
        const weight =
          weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
        const bmi = weight / ((tall / 100) * (tall / 100))
        setBMI(bmi)
      }
    }
  }, [props.active])

  const renderFitnessLevel = (param: string) => {
    switch (param) {
      case 'Almost every day':
        return 'Advanced'
      case 'Several times a week':
        return 'Intermediate'
      default:
        return 'Beginner'
    }
  }

  return (
    <div className="px-4 py-6 rounded-r16 border border-[#17171714] mb-6 pb-0">
      <BMIBetterme bmi={bmi} answers={props.answers} active={props.active} />
      <div className="flex justify-between pb-6  overflow-hidden -mx-4 px-4 mt-4">
        <div>
          <div className="mt-2 flex items-center">
            <img className="min-w-10 mr-2" src={person} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-medium text-xs2">{t('Current weight')}</h6>
              <p className="block leading-5 text-dark font-semibold font-girloy ">
                {currentWeight?.[0]} {currentWeight?.[1]}
              </p>
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <img className="min-w-10 mr-2" src={lifestyle} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-medium text-xs2">{t('Goal')}</h6>
              <p className="block leading-5 text-dark font-girloy  font-semibold">
                {goalWeight?.[0]} {goalWeight?.[1]}
              </p>
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <img className="min-w-10 mr-2" src={level} width="24" height="24" alt="icon" />
            <div>
              <h6 className="font-medium text-xs2">{t('Fitness Level')}</h6>
              <p className="block leading-5  text-dark font-girloy font-semibold ">
                {renderFitnessLevel(fitnessLevel as string)}
              </p>
            </div>
          </div>
        </div>
        <div className="-mb-10 relative min-w-40 max-w-40">
          <div className="animation-arrow">
            <svg
              width="78"
              height="264"
              viewBox="0 0 78 264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows-wall-pilates-brand_arrow__GyNqH absolute bottom-0 -right-0"
            >
              <path
                opacity="0.8"
                d="M76.8172 4.5851C77.9721 2.53484 76.4906 0 74.1375 0C73.027 0 72.0027 0.598633 71.4577 1.5662L3.52781 122.164C0.0959549 128.257 0.0954132 135.699 3.5264 141.793L71.458 262.434C72.0028 263.401 73.0269 264 74.1374 264C76.4903 264 77.9714 261.465 76.8163 259.415L10.562 141.848C7.12773 135.754 7.12697 128.308 10.56 122.213L76.8172 4.5851Z"
                fill="currentColor"
              ></path>
            </svg>
            <svg
              width="78"
              height="264"
              viewBox="0 0 78 264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows-wall-pilates-brand_arrow__GyNqH absolute bottom-0 -right-0"
            >
              <path
                opacity="0.8"
                d="M76.8172 4.5851C77.9721 2.53484 76.4906 0 74.1375 0C73.027 0 72.0027 0.598633 71.4577 1.5662L3.52781 122.164C0.0959549 128.257 0.0954132 135.699 3.5264 141.793L71.458 262.434C72.0028 263.401 73.0269 264 74.1374 264C76.4903 264 77.9714 261.465 76.8163 259.415L10.562 141.848C7.12773 135.754 7.12697 128.308 10.56 122.213L76.8172 4.5851Z"
                fill="currentColor"
              ></path>
            </svg>
            <svg
              width="78"
              height="264"
              viewBox="0 0 78 264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows-wall-pilates-brand_arrow__GyNqH absolute bottom-0 -right-0"
            >
              <path
                opacity="0.8"
                d="M76.8172 4.5851C77.9721 2.53484 76.4906 0 74.1375 0C73.027 0 72.0027 0.598633 71.4577 1.5662L3.52781 122.164C0.0959549 128.257 0.0954132 135.699 3.5264 141.793L71.458 262.434C72.0028 263.401 73.0269 264 74.1374 264C76.4903 264 77.9714 261.465 76.8163 259.415L10.562 141.848C7.12773 135.754 7.12697 128.308 10.56 122.213L76.8172 4.5851Z"
                fill="currentColor"
              ></path>
            </svg>
            <svg
              width="78"
              height="264"
              viewBox="0 0 78 264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animation-arrows-wall-pilates-brand_arrow__GyNqH absolute bottom-0 -right-0"
            >
              <path
                opacity="0.8"
                d="M76.8172 4.5851C77.9721 2.53484 76.4906 0 74.1375 0C73.027 0 72.0027 0.598633 71.4577 1.5662L3.52781 122.164C0.0959549 128.257 0.0954132 135.699 3.5264 141.793L71.458 262.434C72.0028 263.401 73.0269 264 74.1374 264C76.4903 264 77.9714 261.465 76.8163 259.415L10.562 141.848C7.12773 135.754 7.12697 128.308 10.56 122.213L76.8172 4.5851Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          {bmi < 18.5 && (
            <Image
              className="max-w-40 w-full mx-auto transition-all duration-300 relative"
              src="/img/betterme/range-1.jpg"
              webp="/img/betterme/range-1.webp"
              alt="body image"
            />
          )}
          {bmi >= 18.5 && bmi < 25 && (
            <Image
              className="max-w-40 w-full mx-auto transition-all duration-300 relative"
              src="/img/betterme/range-2.jpg"
              webp="/img/betterme/range-2.webp"
              alt="body image"
            />
          )}
          {bmi >= 25 && bmi < 30 && (
            <Image
              className="max-w-40 w-full mx-auto transition-all duration-300 relative"
              src="/img/betterme/range-3.jpg"
              webp="/img/betterme/range-3.webp"
              alt="body image"
            />
          )}
          {bmi >= 30 && (
            <Image
              className="max-w-40 w-full mx-auto transition-all duration-300 relative"
              src="/img/betterme/range4.jpg"
              webp="/img/betterme/range4.webp"
              alt="body image"
            />
          )}
        </div>
      </div>
    </div>
  )
}
