import { StripeError } from '@stripe/stripe-js'
import React from 'react'

interface StripeErrorModalProps {
  isOpen?: boolean
  error?: StripeError
  onClose: () => void
}

export default function StripeErrorModal({ error, isOpen, onClose }: StripeErrorModalProps) {
  if (!error) return null

  const errorMessage = () => {
    const declineCode = error.decline_code

    if (declineCode === 'insufficient_funds') {
      return (
        <h2 className="text-xl3 font-extrabold p-0 m-0">
          Oops! Your payment was <mark>declined</mark>
          <br />
          <br />
          Please <mark>try again</mark> or use another payment method
        </h2>
      )
    }

    if (declineCode === 'do_not_honor') {
      return (
        <h2 className="text-xl3 font-extrabold p-0 m-0">
          Oops! Seems like you have <mark>insufficient funds</mark>
          <br />
          <br />
          Please <mark>try another card </mark>or payment method
        </h2>
      )
    }

    return (
      <>
        <h2 className="text-xl3 font-extrabold p-0 m-0 mb-4">The payment failed</h2>
        <p>{error.message}</p>
      </>
    )
  }

  return (
    <div
      className={`${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-lightBlue  top-0 left-0 flex  items-center justify-center w-full h-screen backdrop-blur-sm p-9`}
    >
      <div className="flex flex-col items-center justify-center  bg-white text-center border-2 border-borderInput rounded-r12 pt-3 pb-6 px-7 shadow-popup">
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.0006 8.66634C16.4277 8.66634 8.66732 16.4267 8.66732 25.9997C8.66732 35.5726 16.4277 43.333 26.0006 43.333C35.5736 43.333 43.334 35.5726 43.334 25.9997C43.334 16.4267 35.5736 8.66634 26.0006 8.66634ZM4.33398 25.9997C4.33398 14.0335 14.0345 4.33301 26.0006 4.33301C37.9668 4.33301 47.6673 14.0335 47.6673 25.9997C47.6673 37.9658 37.9668 47.6663 26.0006 47.6663C14.0345 47.6663 4.33398 37.9658 4.33398 25.9997ZM26.0006 15.1663C27.1973 15.1663 28.1673 16.1364 28.1673 17.333V25.9997C28.1673 27.1963 27.1973 28.1663 26.0006 28.1663C24.804 28.1663 23.834 27.1963 23.834 25.9997V17.333C23.834 16.1364 24.804 15.1663 26.0006 15.1663Z"
            fill="#FF0000"
          />
          <path
            d="M28.1673 34.6667C28.1673 35.8633 27.1973 36.8333 26.0007 36.8333C24.804 36.8333 23.834 35.8633 23.834 34.6667C23.834 33.47 24.804 32.5 26.0007 32.5C27.1973 32.5 28.1673 33.47 28.1673 34.6667Z"
            fill="#FF0000"
          />
        </svg>
        <div className="mt-5 mb-10">{errorMessage()}</div>
        <button
          onClick={onClose}
          className="min-w-[225px] text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-3 font-bold active:scale-95 transition-all cursor-pointer"
        >
          OK
        </button>
      </div>
    </div>
  )
}
