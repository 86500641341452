import React from 'react'
import { sliderBlockItemType } from '@/types/types'

interface ReviewPopup {
  isOpen: boolean
  onClose: (review: undefined, isOpen: boolean) => void
  slide: sliderBlockItemType | undefined
}

const ReviewPopup = (props: ReviewPopup) => {
  if (!props.isOpen) return null
  return (
    <div className="fixed top-0 z-[100] left-0 w-full h-full flex flex-col items-center justify-center">
      <button
        onClick={() => props.onClose(undefined, false)}
        className="fader absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,.8)] backdrop-blur-md"
      ></button>
      <div className="font-girloy relative p-4 z-10 max-w-[calc(390px-2.5rem)] w-full rounded-r16 bg-grayB">
        <div className="flex flex-row justify-between items-center">
          <div className="font-bold text-xl2">{props?.slide?.name}</div>
          <div
            onClick={() => props.onClose(undefined, false)}
            className="w-5 bg-[#d9d9d98f] rounded-full h-5 cursor-pointer text-lg flex justify-center items-center"
          >
            <svg viewBox="0 0 24 24" width="1em" height="1em">
              <path d="M7.246 7.246a.84.84 0 0 0 0 1.189L10.812 12l-3.566 3.565a.84.84 0 0 0 1.189 1.189L12 13.188l3.565 3.566a.84.84 0 0 0 1.189-1.189L13.188 12l3.566-3.565a.84.84 0 0 0-1.189-1.189L12 10.812 8.435 7.246a.84.84 0 0 0-1.189 0Z"></path>
            </svg>
          </div>
        </div>
        {!props?.slide?.res && (
          <div className="flex mt-1">
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              data-icon="StarFullIcon"
              className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
              fill="#E9A43F"
            >
              <path
                fillRule="evenodd"
                d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              data-icon="StarFullIcon"
              className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
              fill="#E9A43F"
            >
              <path
                fillRule="evenodd"
                d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              data-icon="StarFullIcon"
              className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
              fill="#E9A43F"
            >
              <path
                fillRule="evenodd"
                d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              data-icon="StarFullIcon"
              className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
              fill="#E9A43F"
            >
              <path
                fillRule="evenodd"
                d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              data-icon="StarFullIcon"
              className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
              fill="#E9A43F"
            >
              <path
                fillRule="evenodd"
                d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        )}
        {props?.slide?.res && (
          <p className="mt-1 text-[rgba(23,23,23,0.72)] font-bold">{props.slide.res}</p>
        )}
        <p className="text-left text-[rgba(23,23,23,0.72)] leading-normal mt-4 font-normal text-md2">
          {props?.slide?.review}
        </p>
      </div>
    </div>
  )
}

export default ReviewPopup
