import React, { ChangeEvent, useEffect, useRef } from 'react'
import { Answer, Answers, CheckboxButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface CheckboxButtonProps {
  attributes: CheckboxButtonType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
  answers: Answers
}

export default function CheckboxButtonBetterme(props: CheckboxButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parent = e.target.closest('.steps') ?? document
    const reset: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[data-reset]')
    const allCheck: NodeListOf<HTMLInputElement> =
      parent.querySelectorAll('input[data-choosed-all]')
    if (e.target.checked && !e.target.dataset.reset && !e.target.dataset.choosedAll) {
      props.onAnswer({ [props.name]: [e.target.value] })
      reset.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
      allCheck.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    } else if (!e.target.checked && !e.target.dataset.reset && !e.target.dataset.choosedAll) {
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
      allCheck.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    }
    if (e.target.checked && e.target.dataset.reset) {
      const targetName = e.target.getAttribute('name')
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = element.getAttribute('name') === targetName && e.target === element
      })
      props.onAnswer({ [props.name]: [e.target.value] })
    } else if (!e.target.checked && e.target.dataset.reset) {
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
    }
    if (e.target.dataset.choosedAll && e.target.checked) {
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element && element.checked === false) {
          props.onAnswer({ [props.name]: [element.value] })
          element.checked = true
        }
      })
      e.target.checked = true
      props.onAnswer({ [props.name]: [e.target.value] })
    } else if (e.target.dataset.choosedAll && !e.target.checked) {
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = false
      })
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
      e.target.checked = false
    }
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    const inputs = document.querySelectorAll('input')
    if (choosedValue && typeof choosedValue === 'string') {
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
    if (choosedValue && typeof choosedValue === 'object') {
      choosedValue.forEach((element) => {
        inputs.forEach((input) => {
          if (input.value === element) {
            input.checked = true
          }
        })
      })
    }
  }, [])
  return (
    <div>
      {!props.attributes?.cardView && (
        <label
          className={
            "flex bg-white items-center checkbox font-girloy font-semibold border relative border-border mb-3 mt-0 rounded-r16 p-4 pr-10  cursor-pointer  transition duration-300  after:absolute after:content=[''] after:top-1/2 after:w-6 after:h-6  after:border after:border-checkBorder  after:right-4 after:rounded-r4 after:-translate-y-1/2 active:scale-95" +
            (checkbox.current?.checked
              ? ' !bg-gray   border-gray after:!bg-dark after:border-dark border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
              : '') +
            ' ' +
            (props.attributes?.className ? 'w-45 pr-9 after:right-3 text-md2 !py-3.5 min-h-16' : '')
          }
        >
          {props.attributes?.img && (
            <span className="flex items-center min-w-10 max-w-10 mr-4">
              <img
                className="max-w-10 h-auto w-auto"
                width={40}
                height={40}
                alt="Checkbox img"
                src={props.attributes?.img}
              />
            </span>
          )}
          {props.attributes.text}
          <input
            type="checkbox"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            name={props.name}
            value={props.attributes.hideText ? props.attributes.hideText : props.attributes.text}
            className="hidden"
            ref={checkbox}
            data-reset={props.attributes.resetCheckbox}
            data-choosed-all={props.attributes.allCheckbox}
          />
        </label>
      )}
      {props.attributes?.cardView && (
        <label
          className={
            'flex justify-between h-20 bg-white items-center checkbox font-girloy font-semibold border relative border-border mb-3 mt-0 rounded-r16   cursor-pointer  transition duration-300 active:scale-95' +
            (checkbox.current?.checked ? ' !bg-gray   border-gray' : '') +
            ' ' +
            (props.attributes?.className ? 'w-45 pr-9 after:right-3 text-md2 !py-3.5 min-h-16' : '')
          }
        >
          <span className="p-4">{props.attributes.text}</span>
          <input
            type="checkbox"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            name={props.name}
            value={props.attributes.hideText ? props.attributes.hideText : props.attributes.text}
            className="hidden"
            ref={checkbox}
            data-reset={props.attributes.resetCheckbox}
            data-choosed-all={props.attributes.allCheckbox}
          />
          {props.attributes?.img && (
            <span className="absolute right-0 top-0 h-full">
              <img
                className=" h-full w-auto"
                width={40}
                height={40}
                alt="Checkbox img"
                src={props.attributes?.img}
              />
            </span>
          )}
        </label>
      )}
    </div>
  )
}
