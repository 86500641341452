import { Answer, Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'

interface LoaderProps {
  answers: Answers
  active?: boolean
  mode?: string
  triggerNextStep: () => void
  onAnswer: (answer: Answer, step?: number) => void
}

const INITIAL_OFFSET = 25
const circleConfig = {
  viewBox: '0 0 38 38',
  x: '19',
  y: '19',
  radio: '15.91549430918954',
}

const pace = 100

export const LoaderBetterme = (props: LoaderProps) => {
  const [progressBar, setProgressBar] = useState(0)

  useEffect(() => {
    if (!props.active) return

    const timeoutId = setTimeout(() => {
      if (progressBar >= 100) {
        props.triggerNextStep()
        return
      }
      setProgressBar(progressBar + 1)
    }, pace)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [progressBar, props.active])

  useEffect(() => {
    if (props.active) return
    setProgressBar(0)

    return () => {
      setProgressBar(0)
    }
  }, [props.active])

  return (
    <div className=" relative pt-6 bg-white z-40">
      <figure className="relative flex justify-center">
        <svg viewBox={circleConfig.viewBox} className="w-[200px]">
          <circle
            className="donut-segment"
            cx={circleConfig.x}
            cy={circleConfig.y}
            r={circleConfig.radio}
            fill="transparent"
            stroke="#F6F7F9"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <circle
            className="donut-segment"
            cx={circleConfig.x}
            cy={circleConfig.y}
            r={circleConfig.radio}
            fill="transparent"
            stroke="#EA749B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray={`${progressBar} ${100 - progressBar}`}
            strokeDashoffset={INITIAL_OFFSET}
          />
        </svg>
        <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl5 font-semibold font-girloy">
          {progressBar}
          <span className="text-xl3 font-normal">%</span>
        </p>
      </figure>
      <h2 className="text-xl mt-4 pt-0 text-center font-medium">
        Creating your personalized Wall Pilates Workout Plan
      </h2>
      <h3 className="text-xl45 text-center font-girloy pt-6 mt-6 border-t border-borderHeader text-green font-semibold">
        150 million people
      </h3>
      <p className="text-base1 mt-2 text-center">have chosen Fit4me</p>
    </div>
  )
}
