import React from 'react'
import { Plan } from '@/types/types'
import { formattedPrice } from '@/helpers/formatPrice'
import { useTranslation } from 'react-i18next'
import arrow from '@/assets/images/arrow-right.svg'
import tick from '@/assets/scss/tick.svg'
import { Button } from '../Button/Button'

interface PlansProps {
  descriptionTrial: string
  handleShowCheckout: () => void
  paymentMethod: string
  activePlan: number
  isDiscountPrice: boolean
  isFreeTrial: boolean
  handlePlan: (index: number) => void
  plans: Plan[]
  plansReserve: Plan[]
}

export function PlansBetterme(props: PlansProps) {
  const { t } = useTranslation()

  const handleCheckout = () => {
    localStorage.setItem('activePlan', JSON.stringify(props.plans[props.activePlan]))
    localStorage.setItem('reserveActivePlan', JSON.stringify(props.plansReserve[props.activePlan]))
    props.handleShowCheckout()
  }

  return (
    <div className="plans ">
      {!props.isFreeTrial &&
        props.plans.map((el, index) => {
          const price = props.isDiscountPrice ? el.discountedPrice45 : el.discountedPrice30
          const pricePerDayDiscount = props.isDiscountPrice ? el.pricePerDay45 : el.pricePerDay30
          return (
            <div
              key={el.name}
              onClick={() => {
                props.handlePlan(index)
              }}
              className={`${!el.mostPopular && index === props.activePlan ? 'bg-white text-dark' : 'text-[#171717b8] bg-grayB'} ${index === props.activePlan ? 'text-dark active bg-white before:border-green2 after:opacity-100' : 'text-[#171717b8] after:opacity-0'}  p-2.5 px-4 plan-item overflow-hidden mt-2  rounded-r16  relative cursor-pointer transition-all before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full before:rounded-r16 before:transition-all  before:border-[#17171714] before:border after:absolute after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:rounded-r16 after:transition-all  after:border-green2 after:border-2 active:scale-95`}
            >
              {el.mostPopular && (
                <div
                  className={`${index === props.activePlan ? 'bg-green2' : 'bg-grayE'} text-center mb-1 -mt-2.5 -mx-6 px-4 font-girloy  text-white font-semibold min-h-6 flex items-center justify-center text-base1 transition-all`}
                >
                  {t('MOST POPULAR')}
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div
                    className={`${index === props.activePlan ? 'bg-green2 border-green2 [&>img]:opacity-100' : ' bg-lightGray border-checkBorder [&>img]:opacity-0'} min-w-5 flex items-center justify-center max-w-5 h-5 rounded-full border border-checkBorder mr-4 transition-all`}
                  >
                    <img className="w-3" src={tick} alt="tick" />
                  </div>
                  <div>
                    <span className="block font-semibold font-girloy text-xl3">
                      {el.name} {t('Plan')}
                    </span>
                    {index === 0 && (
                      <span className="text-xs3 py-1 px-2 rounded-r6 bg-[#1717170f]">
                        4-week PLAN
                      </span>
                    )}
                    <span className="text-md flex">
                      <s className="opacity-70 red-strike"> ${formattedPrice(el.originalPrice)}</s>
                      <img className="w-4 h-auto mb-px" src={arrow} alt="arrow-right" />
                      <span className="font-medium opacity-70 ">${formattedPrice(price)}</span>
                    </span>
                  </div>
                </div>
                <div className="text-price pl-4 flex items-center justify-end text-center relative min-w-price ">
                  <span className="block py-2 px-3 relative w-full">
                    <s className=" block opacity-80 text-xs2 font-regular leading-none red-strike">
                      {' '}
                      ${formattedPrice(el.pricePerDay)}
                    </s>
                    <span className="text-xl35 font-girloy font-semibold  block">
                      ${pricePerDayDiscount.toFixed(2)}
                    </span>
                    <span className="text-xs2 opacity-70 leading-none block font-medium">
                      {t('per day')}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      <Button
        variant="plan"
        onClick={handleCheckout}
        className="mt-6  !p-4.5 cursor-pointer font-semibold btn-animation font-girloy"
      >
        {props.isFreeTrial ? 'Start trial' : t('Get my plan')}
      </Button>
      <p className="mt-0 text-md border border-borderHeader  text-[#1717178f] p-3 rounded-r16 text-center mt-6">
        {props.isFreeTrial ? props.descriptionTrial : props.plans[props.activePlan].instruction}
      </p>
    </div>
  )
}
