import React, { createContext, useState, useEffect, useContext } from 'react'
import { selectPaymentMethod } from '@/helpers/facebookPixelEvents'

type PaddleContextType = any

export const PaddleContext = createContext<PaddleContextType>(null)

export const PaddleProvider = ({
  children,
  handleIsLoading,
}: {
  children: React.ReactNode
  handleIsLoading: (loading: boolean) => void
}) => {
  const [paddle, setPaddle] = useState<any>(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js'
    script.async = true

    script.onload = () => {
      const windowObj = window as any
      const Paddle = windowObj.Paddle
      if (process.env.REACT_APP_MODE === 'dev') {
        Paddle.Environment.set('sandbox')
      }
      Paddle.Initialize({
        token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
        eventCallback: (event: any) => {
          if (event?.name === 'checkout.loaded') {
            handleIsLoading(false)
          }
          if (event?.name === 'checkout.payment.selected') {
            selectPaymentMethod(event?.data?.payment?.method_details?.type)
          }
        },
      })
      setPaddle(Paddle)
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <PaddleContext.Provider value={paddle}>{children}</PaddleContext.Provider>
}

export const usePaddleContext = () => {
  const context = useContext(PaddleContext)
  if (context === undefined) {
    throw new Error('usePaddleContext was used outside of its Provider')
  }
  return context
}
