import React, { useEffect, useState } from 'react'
import appStore from '@/assets/images/app-store.svg'
// import googlePlay from '@/assets/images/google-play.svg'
import enjoyPlan from '@/assets/images/iphone.png'
import enjoyPlanWebp from '@/assets/images/iphone.webp'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Image from 'react-image-webp'

export default function ThankYou() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const token = location.state?.token
  const customerPortalId = location.state?.customerPortalId
  const [appUrl, setAppUrl] = useState('')
  // const [appUrlGoogle, setAppUrlGoogle] = useState('')

  useEffect(() => {
    if (token === undefined) {
      navigate('/')
    } else {
      setAppUrl(
        `https://fit4me.onelink.me/DGb0/5sooaimb?mode=auth&refresh_token=${token}&user_id=${customerPortalId}`,
      )
      // setAppUrlGoogle(
      //   `https://play.google.com/store/apps/details?id=com.whiteapps.fit4me.dev&referrer=${encodeURI(`refresh_token=${token}&user_id=${customerPortalId}`)}`,
      // )
    }
  }, [token, customerPortalId])

  return (
    <div className="w-full pt-3 px-4 pb-4 flex flex-col h-full overflow-y-auto overflow-x-hidden">
      <h2 className="text-xl3 text-center text-green  font-semibold pt-0 pb-3 border-b -mx-4 border-borderInput mb-4">
        {t('Congrats')}!
      </h2>
      <h3 className="[&>mark]:bg-transparent [&>mark]:font-bold [&>mark]:text-green text-xl3 text-center font-semibold">
        {t('You are') + ' '}
        <mark>{t('in')}!</mark>
        <br />
        😉
      </h3>
      <h4 className="[&>mark]:bg-transparent [&>mark]:font-bold [&>mark]:text-green text-center text-xl3 mt-9">
        <mark>{t('Step')} 1.</mark>
        {' ' + t('Download the app from') + ' '}
        <mark>{t('App Store')}</mark>
        {/* {t('or')} <mark>{t('Google Play')}</mark> */}
      </h4>
      <a className="mx-auto mt-6" href={appUrl} download target="_blank" rel="noreferrer">
        <img className="m-w-full" width="258" height="75" src={appStore} alt="App store" />
      </a>
      {/* <a className="mx-auto mt-6" href={appUrlGoogle} download target="_blank" rel="noreferrer"> */}
      {/*  <img className="m-w-full" width="258" height="75" src={googlePlay} alt="GooglePay" /> */}
      {/* </a> */}
      <h4 className="[&>mark]:bg-transparent mt-6 [&>mark]:font-bold [&>mark]:text-green text-center text-xl3">
        <mark>{t('Step')} 2. </mark>
        Log In and enjoy your <mark>{t('Personal Wall Pilates Plan')}</mark>
      </h4>
      <Image
        className="mx-auto m-w-full mt-8"
        width="314"
        height="641"
        src={enjoyPlan}
        webp={enjoyPlanWebp}
        alt="Iphone 15"
      />
    </div>
  )
}
