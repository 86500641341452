import React from 'react'
import start1 from '@/assets/images/start-1.png'
import start2 from '@/assets/images/start-2.png'
import start3 from '@/assets/images/start-3.png'
import start4 from '@/assets/images/start-4.png'
import start1Webp from '@/assets/images/start-1.webp'
import start2Webp from '@/assets/images/start-2.webp'
import start3Webp from '@/assets/images/start-3.webp'
import start4Webp from '@/assets/images/start-4.webp'
import arrow from '@/assets/images/base-arrow-circle.svg'
import Image from 'react-image-webp'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface StartScreenProps {
  onStart: () => void
}

export const StartScreen = (props: StartScreenProps) => {
  const { t } = useTranslation()
  const setToLocalStorageAgePlaceholder = (age: string) => {
    localStorage.setItem('placeholderAge', age)
  }
  const items = [
    {
      title: 'Age: 18-29',
      img: start1,
      imgWebp: start1Webp,
      placeholder: '25',
    },
    {
      title: 'Age: 30-39',
      img: start2,
      imgWebp: start2Webp,
      placeholder: '35',
    },
    {
      title: 'Age: 40-49',
      img: start3,
      imgWebp: start3Webp,
      placeholder: '45',
    },
    {
      title: 'Age: 50+',
      img: start4,
      imgWebp: start4Webp,
      placeholder: '55',
    },
  ]
  return (
    <>
      <h1 className="text-center font-semibold text-xl4 mx-auto mt-20 font-girloy">
        <span className="text-green">WALL PILATES</span> PLAN
      </h1>
      <div className="flex mt-2 justify-center flex-wrap gap-3 font-girloy text-[#1717178f] font-semibold">
        <p className="text-base">BASED ON YOUR AGE</p>
      </div>
      <div className="grid grid-cols-2 gap-3 mt-7">
        {items.map((el) => (
          <div
            key={el.title}
            className="rounded-r12 cursor-pointer overflow-hidden col-span-1 bg-[#F6F7F9]"
            onClick={() => {
              props.onStart()
              setToLocalStorageAgePlaceholder(el.placeholder)
            }}
          >
            <Image
              className="max-w-full h-[140px] w-full object-cover mx-auto"
              width="130"
              height="160"
              src={el.img}
              webp={el.imgWebp}
              alt="Image"
            />
            <span className="bg-green text-xl2 font-semibold font-girloy text-white w-full flex justify-between items-center  p-3">
              {el.title}
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <img className="w-2 ml-[2px]" width="5" height="12" src={arrow} alt="Image" />
              </div>
            </span>
          </div>
        ))}
      </div>

      <div className="flex flex-col justify-end mt-6">
        <p className="text-xs2_1 text-center">
          By choosing your age and continuing you agree to our{' '}
          <Link className="transition-all hover:text-primary" to="/terms">
            Terms of Service
          </Link>{' '}
          |{' '}
          <Link className="transition-all hover:text-primary" to="/privacy-policy">
            {t('Privacy Policy')}
          </Link>
        </p>
      </div>
    </>
  )
}
