import { Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipBetterme } from '@/components/QuestionsUI/TooltipBetterme'
import danger from '@/assets/images/dangerBet.svg'
import warning from '@/assets/images/warningBet.svg'
import good from '@/assets/images/goodBet.svg'
import { getValue } from '@/helpers/getObjectValue'

interface BMIProps {
  active: boolean
  answers: Answers
  bmi: number
}
export function BMIBetterme(props: BMIProps) {
  const { t } = useTranslation()
  const [percent, setPercent] = useState('')
  const heightProps = getValue('human-height', props.answers)
  const weightProps = getValue('current-weight', props.answers)
  useEffect(() => {
    if (props.active) {
      if (heightProps && weightProps) {
        const tall =
          heightProps && heightProps[1] === 'FT' ? +heightProps[0] * 30.48 : +heightProps[0]
        const weight =
          weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
        const bmi = weight / ((tall / 100) * (tall / 100))
        if (bmi > 30) {
          setPercent('93%')
        } else if (bmi < 15) {
          setPercent('7%')
        } else {
          setPercent((bmi - 17) * 5.4 + 14 + '%')
        }
      }
    }
  }, [props.active])
  return (
    <div className="">
      {props.active}
      <div className="flex items-center justify-between">
        <h6 className="text-xl2 font-semibold font-girloy text-dark">
          {t('Body Mass Index (BMI)')}
        </h6>
      </div>
      <div className="">
        <div className="relative pt-14">
          <div
            style={{ left: percent }}
            className={`${props.active ? 'scale-100 bmi-transition' : 'scale-50'} mb-6 absolute z-20 transition-all left-0 -bottom-1.5 -translate-x-1/2  after:-bottom-5 after:absolute  after:z-20 after:shadow-betterme after:outline after:outline-4 after:outline-[#1717170f] after:content-['']  after:left-1/2 after:-translate-x-1/2 after:w-4 after:h-4 after:rounded-full  after:bg-white  text-center`}
          >
            <span className="block relative opacity-0 -top-8 -left-px py-1 px-2.5 rounded-lg bg-black text-white font-medium text-md before:absolute before:content-['']  before:left-1/2 before:-translate-x-1/2 before:-bottom-0.5 before:w-2 before:h-2 before:bg-black before:rotate-45 whitespace-nowrap after:absolute after::content-[''] after:w-px after:h-10 after:bg-dark after:top-full after:left-1/2 after:-translate-x-1/2">
              {t('You')} — {props.bmi.toFixed(1)}
            </span>
          </div>
          <div className="flex items-center justify-between mb-1.5 pb-1.5 border-b border-[#1717170f]">
            <span className="block text-xs2  text-dark relative after:absolute after::content-[''] after:w-px after:h-9 after:bg-[#1717170f] after:top-full after:left-1/2 after:-translate-x-1/2">
              15
            </span>
            <span className="block text-xs2  text-dark relative after:absolute after::content-[''] after:w-px after:h-9 after:bg-[#1717170f] after:top-full after:left-1/2 after:-translate-x-1/2">
              18.5
            </span>
            <span className="block text-xs2  text-dark relative after:absolute after::content-[''] after:w-px after:h-9 after:bg-[#1717170f] after:top-full after:left-1/2 after:-translate-x-1/2">
              25
            </span>
            <span className="block text-xs2  text-dark relative after:absolute after::content-[''] after:w-px after:h-9 after:bg-[#1717170f] after:top-full after:left-1/2 after:-translate-x-1/2">
              30
            </span>
            <span className="block text-xs2  text-dark relative after:absolute after::content-[''] after:w-px after:h-9 after:bg-[#1717170f] after:top-full after:left-1/2 after:-translate-x-1/2">
              40
            </span>
          </div>
          <div className="h-2 rounded-r16 w-full bg-progressLinearBetterme relative z-10"></div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <span className="block text-xs2 uppercase text-dark">{t('Underweight')}</span>
          <span className="block text-xs2 uppercase text-dark">{t('Normal')}</span>
          <span className="block text-xs2 uppercase text-dark">{t('Overweight')}</span>
          <span className="block text-xs2 uppercase text-dark">{t('Obese')}</span>
        </div>
      </div>
      {+props.bmi.toFixed(1) >= 30 && (
        <TooltipBetterme
          title="Risks of unhealthy BMI:"
          description="High blood pressure, increased risk of heart attack, stroke, type 2 diabetes, chronic back and joint pain"
          icon={danger}
          type="danger"
        />
      )}
      {+props.bmi.toFixed(1) < 30 && +props.bmi.toFixed(1) >= 25 && (
        <TooltipBetterme
          title="Risks of unhealthy BMI:"
          description="High blood pressure, increased risk of heart attack, stroke, type 2 diabetes, chronic back and joint pain"
          icon={warning}
          type="warning"
        />
      )}
      {+props.bmi.toFixed(1) < 25 && +props.bmi.toFixed(1) >= 18.5 && (
        <TooltipBetterme
          title="Healthy BMI:"
          description="Good starting BMI to tone up and get your dream body"
          icon={good}
          type="good"
        />
      )}
    </div>
  )
}
