import { sliderBlockItemType } from '@/types/types'
import React, { useRef } from 'react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

interface ReviewsSliderProps {
  slides: sliderBlockItemType[]
  title: string
  onOpenReview: (review: sliderBlockItemType, isOpen: boolean) => void
}

const ReviewsSlider = (props: ReviewsSliderProps) => {
  const swiperRef = useRef<SwiperRef>(null)
  return (
    <div className="flex flex-col py-4">
      <div>
        <p
          className="[&>mark]:bg-transparent  text-xl45  font-semibold font-girloy  text-center mb-6 tracking-tight "
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></p>
      </div>
      <div className="w-full flex flex-row">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          slidesPerView={1}
          ref={swiperRef}
          pagination={{
            clickable: true,
            bulletActiveClass: '!bg-dark !opacity-100',
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          loop={true}
          className="w-full !pb-5"
        >
          <div className="flex flex-col">
            {props.slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="rounded-r16 overflow-hidden border border-borderHeader">
                  <div className="flex flex-col rounded-button shadow-button bg-white">
                    <div className="flex flex-row gap-x-2 relative">
                      <img src={slide.img} className="rounded-tr-button" alt="Review" />
                      <div className="absolute bottom-2 left-2 right-2 text-center py-2 font-medium text-lg bg-white rounded-r8 text-base1 font-medium">
                        {slide.name}, <mark className="bg-transparent text-green">{slide.res}</mark>
                      </div>
                    </div>
                    <div className="p-4">
                      <p className="text-base1 opacity-70">{slide.review?.slice(0, 300)}...</p>
                      <div className="flex justify-center mt-2">
                        <button
                          className="text-md font-medium text-center opacity-70"
                          onClick={() => props.onOpenReview(slide, true)}
                        >
                          Read more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
          <div className="swiper-button-prev custom-swiper-button" />
          <div className="swiper-button-next custom-swiper-button" />
        </Swiper>
      </div>
    </div>
  )
}

export default ReviewsSlider
