import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentPlan, Subscription } from '@/types/types'
// import Subtotal from '@/components/CheckoutUI/Subtotal'
import { Button } from '@/components/Button/Button'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { NavigateFunction } from 'react-router-dom'
import { createScheduleSubscriptionForCustomer } from '@/helpers/stripeHelpers'
import PayPallIcon from '@/assets/images/PayPal.svg'
import ApplePay from '@/assets/images/Apple_Pay_logo white.svg'
import VerifiedIcon from '@/assets/images/verified.svg'
import {
  clickPurchaseButton,
  selectPaymentMethod,
  trackPaymentErrors,
} from '@/helpers/facebookPixelEvents'
import { StripeError } from '@stripe/stripe-js'

interface CheckoutFormProps {
  plan: PaymentPlan
  isDiscount: boolean
  isFreeTrial: boolean
  subscription: Subscription
  navigate: NavigateFunction
  handleError: (error: StripeError) => void
  handleResetPaymentElement: () => void
  handleIsLoading: (loading: boolean) => void
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const defaultButton = { title: 'Continue', class: 'submit-dft' }
  // const defaultPaymentElementClasses = { class: 'h-[90px]' }
  const [counter, setCounter] = useState(0)
  const [submitButton, setSubmitButton] = useState(defaultButton)
  // const [paymentElementClasses, setPaymentElementClasses] = useState(defaultPaymentElementClasses)
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleClickPurchaseButton(submitButton.class === 'submit-dft' ? 'card' : submitButton.class)

    if (!stripe || !elements) {
      return
    }

    props.handleIsLoading(true)

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_PAYMENT_RETURN_URL ?? '',
      },
      redirect: 'if_required',
    })

    if (result.error) {
      props.handleError(result.error)
    } else {
      if (props.plan.nextProductStripeID) {
        createScheduleSubscriptionForCustomer(props.subscription, props.plan).then(() => {
          setSuccessPayment(result, props.navigate)
        })
      } else {
        setSuccessPayment(result, props.navigate)
      }
    }

    props.handleIsLoading(false)
  }

  const handleChangePaymentMethod = (e: { value: { type: string }; complete: boolean }) => {
    // let clasess = { class: 'h-[90px]' }

    switch (e?.value?.type) {
      case 'paypal':
        // clasess = { class: 'h-[90px]' }
        setSubmitButton({ title: 'Continue', class: 'paypall' })
        // setPaymentElementClasses(clasess)
        break
      case 'apple_pay':
        // clasess = { class: 'h-[90px]' }
        setSubmitButton({ title: '', class: 'apple-pay' })
        // setPaymentElementClasses(clasess)
        break
      default:
        // clasess = { class: 'h-auto' }
        setSubmitButton(defaultButton)
        // if (counter !== 0) {
        //   setTimeout(() => {
        //     setPaymentElementClasses(clasess)
        //   }, 200)
        // }
        break
    }
    setCounter((prev) => ++prev)
    setIsActiveSubmitButton(e.complete)
    if (counter > 1) {
      selectPaymentMethod(e.value.type)
    }
  }

  const handleClickPurchaseButton = (method: string) => {
    clickPurchaseButton(method)
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="w-full flex flex-col pb-16">
        <PaymentElement
          onLoadError={(e) => {
            trackPaymentErrors('stripe:onLoading', e)
            props.handleResetPaymentElement()
          }}
          onChange={(e) => {
            handleChangePaymentMethod(e)
          }}
          className="p-2"
          options={{
            fields: { billingDetails: { address: { postalCode: 'auto' } } },
            paymentMethodOrder: ['paypal', 'card', 'apple_pay'],
            terms: { paypal: 'never', card: 'never', applePay: 'never' },
          }}
          onReady={() => props.handleIsLoading(false)}
        />
        <p className="px-2 mt-3 text-md text-[#6d6e78] leading-5">
          By providing your card information, you allow White Apps LTD to charge your card for
          future payments in accordance with their terms.
        </p>
        <div className="mt-4 fixed bottom-4 max-w-content w-full container left-1/2 -translate-x-1/2 z-30">
          <Button disabled={!isActiveSubmitButton} className={`submit ${submitButton.class}`}>
            {submitButton.class === 'paypall' && (
              <img src={PayPallIcon} alt="PaypallIcon" width={90} height={24} />
            )}
            {submitButton.class === 'apple-pay' && (
              <img src={ApplePay} alt="ApplePay" width={55} height={24} />
            )}
            {submitButton.class === 'submit-dft' && (
              <img src={VerifiedIcon} alt="Verified" width={24} height={24} />
            )}
            <span>{submitButton.title}</span>
          </Button>
        </div>
      </form>
    </>
  )
}
