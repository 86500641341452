import React from 'react'
import { ReviewType } from '@/types/types'
import Image from 'react-image-webp'
import stars from '@/assets/images/stars.svg'

export default function Review(props: ReviewType) {
  return (
    <div className="rounded-r12 overflow-hidden border border-borderInput">
      {props.mainTitle.length > 0 && (
        <h3
          className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold  text-center mb-3 mt-5"
          dangerouslySetInnerHTML={{ __html: props.mainTitle }}
        ></h3>
      )}
      <div className="relative w-full h-44">
        {props.src && !props.srcWebp && (
          <img
            className="absolute w-full h-full top-0 left-0 object-cover"
            width="358"
            height="178"
            src={props.src}
            alt="reviw image"
          />
        )}
        {props.srcWebp && (
          <Image
            className="absolute w-full h-full top-0 left-0 object-cover"
            width="358"
            height="178"
            src={props.src}
            webp={props.srcWebp}
            alt="reviw image"
          />
        )}
        <h3 className="absolute z-10 bottom-0 rounded-ss-r20 bg-green right-0 text-base py-1 px-3 font-bold text-white leading-tight">
          {props.title}
        </h3>
      </div>
      <div className="py-3 px-5">
        <div className="flex items-center justify-between mb-3">
          <img
            className=" max-w-full w-auto block"
            width={106}
            height={20}
            alt="Stars"
            src={stars}
          />
          <p className="text-xs3 leading-4 font-medium text-dark opacity-65">{props.name}</p>
        </div>
        <blockquote
          className={`${props.trustpilot ? 'text-left' : 'text-center'} text-dark font-medium text-md`}
        >
          {props.quote}
        </blockquote>
      </div>
    </div>
  )
}
