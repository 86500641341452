import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'

interface GraphProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}
export const PurchaseWithName = (props: GraphProps) => {
  const name = getValue('your-name', props.answers)
  const [loadAnimation, setLoadAnimation] = useState(false)
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const condition = currentWeight && goalWeight && currentWeight[0] < goalWeight[0]

  useEffect(() => {
    if (props.active) {
      setTimeout(() => {
        setLoadAnimation(true)
      }, 500)
    } else {
      setLoadAnimation(false)
    }
  }, [props.active])
  return (
    <>
      <h2 className="text-xl35 -mt-4 font-girloy">
        <mark>{name},</mark> <br />
        your 4-week Wall Pilates Workout Plan is ready!
      </h2>
      <img
        className={`${loadAnimation ? 'active-betterme-graph' : 'opacity-0'} w-full`}
        src={condition ? '/img/base-graph-up.jpg' : '/img/base-graph-down.jpg'}
        alt="graph"
      />
      <p className="text-base text-[#171717b8] mt-2">
        This chart is for illustrative purposes only
      </p>
    </>
  )
}
