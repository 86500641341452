import React, { useEffect, useRef } from 'react'
import { Answer, Answers, RadioButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import Image from 'react-image-webp'

interface RadioButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
  smallWidth?: boolean
  halfWidth?: boolean
}

export default function RadioButtonBetterme(props: RadioButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = () => {
    props.onAnswer({
      [props.name]: [props.attributes.text],
    })
    props.triggerNextStep()
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      const inputs = document.querySelectorAll('input')
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
  }, [])
  return (
    <div>
      {!props.attributes?.withTick && (
        <label
          className={`
            font-semibold text-dark border transition duration-300 border-radio  mb-2 mt-0  rounded-xl cursor-pointer flex items-center bg-transparent active:scale-95 relative overflow-hidden
            ${checkbox.current?.checked ? ' !bg-checked !border-green ' : ''}
            ${props.smallWidth ? ' w-2/3' : ''}
            `}
        >
          {props.attributes?.img && !props.attributes.imgWebp && (
            <img
              className={(props.attributes?.img && 'w-full') + ' max-w-full  w-auto'}
              width={358}
              height={96}
              alt="Body img"
              src={props.attributes?.img}
            />
          )}
          {props.attributes.imgWebp && (
            <Image
              className={(props.attributes?.img && 'w-full') + ' max-w-full  w-auto'}
              width={358}
              height={96}
              alt="Body img"
              src={props.attributes?.img}
              webp={props.attributes?.imgWebp}
            />
          )}
          <span className="absolute block top-1/2 -translate-y-1/2 left-28">
            {props.attributes.text}
          </span>
          <input
            type="radio"
            onClick={handleChange}
            name={props.name}
            value={props.attributes.text}
            className="hidden"
            ref={checkbox}
          />
        </label>
      )}
      {props.attributes?.withTick && (
        <label
          className={`
            flex bg-white leading-5 items-center checkbox font-semibold border relative border-[#EFF1F4] mb-3 mt-0 rounded-xl p-4 pr-14 min-h-[74px] cursor-pointer  transition duration-300 font-girloy  after:absolute after:content=[''] after:top-1/2 after:w-6 after:h-6  after:border after:border-[#17171775] after:bg-transparent after:right-4 after:rounded-full after:-translate-y-1/2 active:scale-95
            ${
              checkbox.current?.checked
                ? ' !bg-gray   border-gray after:!bg-dark after:border-dark border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
                : ''
            } ${props.smallWidth ? ' w-[212px]' : ''} ${props.attributes.halfWidth ? '!mb-0' : ''}`}
        >
          {props.attributes?.img && (
            <span className="flex items-center min-w-6 max-w-6 mr-4">
              <img
                className="max-w-10 h-auto w-auto"
                width={40}
                height={40}
                alt="Radio img"
                src={props.attributes?.img}
              />
            </span>
          )}
          <span
            className={`${!!props.attributes?.img === true ? 'pl-4' : ''} leading-5 text-base text-semibold`}
          >
            {props.attributes.text}
            {props.attributes?.small && (
              <small className="block -mr-3 text-dark text-md font-medium font-inter opacity-65">
                {props.attributes.small}
              </small>
            )}
          </span>
          <input
            type="radio"
            onClick={handleChange}
            name={props.name}
            value={props.attributes.text}
            className="hidden"
            ref={checkbox}
          />
        </label>
      )}
    </div>
  )
}
