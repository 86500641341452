import React, { useEffect, useRef, useState } from 'react'
import { Answer, Answers, HumanSizesType } from '@/types/types'
import close from '@/assets/images/close-betterme.svg'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import { GetMoving } from './GetMoving'
import { Link } from 'react-router-dom'
import { BMITooltipBetterme } from './BMITooltipBetterme'

interface HumanSizesProps {
  onAnswer: (answer: Answer) => void
  attributes: HumanSizesType
  answers: Answers
  active: boolean
}

export default function HumanSizesBetterme(props: HumanSizesProps) {
  const { t } = useTranslation()
  const [activeUnit, setActiveUnit] = useState(props.attributes.active ?? 'null')
  const [ftVal, setFtVal] = useState('')
  const [inVal, setInVal] = useState('')
  const [inputVal, setInputVal] = useState('')
  const [size, setSize] = useState('')
  const [bmi, setBMI] = useState(0)
  const [isSmallBmi, setIsSmallBmi] = useState(false)
  const [goalWeight, setGoalWeight] = useState(0)
  const [checkPrivacy, setCheckPrivacy] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const ref = useRef(null)
  const changeUnits = (unit: string) => {
    setActiveUnit(String(unit))
    if (unit === 'FT' && inputVal !== '') {
      setFtVal(((+size / 30.48).toFixed(1) + '').split('.')[0])
      setInVal(((+size / 30.48).toFixed(1) + '').split('.')[1])
      setSize((+size / 30.48).toFixed(1) + '')
    }
    if (unit === 'CM' && ftVal !== '') {
      setSize(Math.round(+size * 30.48) + '')
      setInputVal(Math.round(+size * 30.48) + '')
    }

    if (unit === 'lbs' && inputVal) {
      const value = Number(inputVal)
      setInputVal(Math.round(value / 0.45359237).toString())
      changedWeight()
    }

    if (unit === 'kg' && inputVal) {
      const value = Number(inputVal)
      setInputVal(Math.round(value * 0.45359237).toString())
      changedWeight()
    }
  }
  const changeBMI = (weight: [number, string]) => {
    const heightProps = getValue('human-height', props.answers)
    const weightProps = weight
    if (heightProps && weightProps) {
      const tall =
        heightProps && heightProps[1] === 'FT' ? +heightProps[0] * 30.48 : +heightProps[0]
      const weight =
        weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
      setBMI(weight / ((tall / 100) * (tall / 100)))
    }
  }

  const changedWeight = () => {
    const weightProps = getValue('current-weight', props.answers)
    if (weightProps) {
      const weight =
        weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
      const goal = activeUnit === 'lbs' ? +inputVal * 0.4536 : +inputVal
      setGoalWeight(((weight - goal) / weight) * 100)
      changeBMI([+inputVal, activeUnit])
    }
  }

  useEffect(() => {
    const choosedValue = getValue(props.attributes.name, props.answers)
    if (choosedValue) {
      setActiveUnit(choosedValue[1])
      setSize(choosedValue[0])
      if (choosedValue[1] === 'FT') {
        setFtVal(((+choosedValue[0]).toFixed(1) + '').split('.')[0])
        setInVal(((+choosedValue[0]).toFixed(1) + '').split('.')[1])
      } else {
        setInputVal(choosedValue[0])
      }
    }
  }, [])
  useEffect(() => {
    if (props.active && props.attributes.name === 'goal-weight' && activeUnit === 'null') {
      const choosedValue = getValue('current-weight', props.answers)
      if (choosedValue) {
        setActiveUnit(choosedValue[1])
      }
    }
  }, [props.active])
  useEffect(() => {
    if (props.attributes.name === 'current-weight') {
      if (String(activeUnit) === 'lbs') {
        if (+inputVal >= 55 && +inputVal <= 305) {
          setSize(inputVal)
          changeBMI([+inputVal, activeUnit])
        } else {
          setSize('')
          setBMI(0)
        }
      }
      if (String(activeUnit) === 'kg') {
        if (+inputVal >= 25 && +inputVal <= 180) {
          setSize(inputVal)
          changeBMI([+inputVal, activeUnit])
        } else {
          setSize('')
          setBMI(0)
        }
      }
    }
    if (props.attributes.name === 'goal-weight' && inputVal) {
      changedWeight()
    }
    if (String(activeUnit) === 'CM') {
      if (+inputVal >= 80 && +inputVal <= 250) {
        setSize(inputVal)
      } else {
        setSize('')
      }
    }
  }, [inputVal, activeUnit])
  useEffect(() => {
    if (ftVal && +ftVal >= 3 && +ftVal < 8) {
      inVal && +inVal > 0 ? setSize(ftVal + '.' + inVal) : setSize(ftVal + '.' + 0)
    } else if (+ftVal === 8) {
      setSize(ftVal + '.' + 0)
      setInVal('0')
    } else {
      setSize('')
    }
  }, [ftVal, inVal])
  useEffect(() => {
    if (props.attributes.name === 'goal-weight' && bmi < 18.5) {
      setSize('')
      setIsSmallBmi(true)
    } else if (props.attributes.name === 'goal-weight') {
      setSize(inputVal)
      setIsSmallBmi(bmi > 26.5)
    }
  }, [bmi, inputVal])
  useEffect(() => {
    size && +size > 0 && !checkPrivacy ? setShowMessage(true) : setShowMessage(false)
    if (props.active && checkPrivacy) {
      size && +size > 0
        ? props.onAnswer({
            [props.attributes.name]: [size, activeUnit],
          })
        : props.onAnswer({
            [props.attributes.name]: [],
          })
    }
  }, [activeUnit, size, checkPrivacy])
  const changeInVal = (e: any) => {
    if (ftVal === '8') {
      setInVal(String(0))
    } else {
      e.target.value.length > 2
        ? setInVal(String(e.target.value).slice(0, 2))
        : setInVal(String(e.target.value))
    }
  }
  const handleCheckPrivacy = () => {
    if (checkPrivacy) {
      props.onAnswer({
        [props.attributes.name]: [],
      })
    }
    setCheckPrivacy(!checkPrivacy)
  }
  return (
    <div className="flex flex-col items-center">
      <div className="inline-flex items-center justify-center p-1 relative bg-grayB rounded-r12">
        {props.attributes.units.map((unit) => {
          return (
            <div
              key={unit}
              className={`${activeUnit === unit ? 'bg-white' : 'opacity-75'} relative transition-all  font-medium rounded-r8  w-[90px] flex items-center justify-center text-md text-center h-6  lowercase`}
              onClick={() => changeUnits(unit)}
            >
              {unit}
            </div>
          )
        })}
      </div>
      {props?.attributes?.name === 'human-height' && (
        <>
          <div className="relative w-full  min-h-[60vh] flex flex-col">
            <div
              className={`${String(activeUnit) === 'FT' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full  duration-300 mt-6`}
            >
              <div className="flex items-center justify-between gap-6  border-b border-dark font-girloy">
                <div className="flex items-center gap-2 p-2">
                  <input
                    className=" block w-full text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75"
                    type="number"
                    inputMode="numeric"
                    onChange={(e) => +e.target.value < 9 && setFtVal(String(e.target.value))}
                    placeholder="Height"
                    value={ftVal}
                  />
                  <span className="text-xl45 font-semibold block">ft</span>
                </div>
                <div className="flex items-center gap-2 p-2">
                  <input
                    className="w-full block text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75"
                    type="number"
                    inputMode="numeric"
                    onChange={(e) => changeInVal(e)}
                    placeholder="Height"
                    value={inVal}
                    ref={ref}
                  />
                  <span className=" text-xl45 font-semibold block">in</span>
                </div>
              </div>
              <p className="text-xs2 text-center mt-2 text-dark">
                {t('Please, enter a value from 3 ft to 8 ft')}
              </p>
            </div>
            <div
              className={`${String(activeUnit) === 'CM' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300 mt-6`}
            >
              <div className="flex items-center gap-2 p-2 border-b border-dark font-girloy">
                <input
                  className=" w-full block text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75 "
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => setInputVal(String(e.target.value))}
                  placeholder="Height"
                  value={inputVal}
                />
                <span className=" text-xl45 font-semibold block">cm</span>
              </div>
              <p className="text-xs2 text-center mt-2 text-dark">
                {t('Please, enter a value from 90 cm to 250 cm')}
              </p>
            </div>
            <div className="grow flex flex-col justify-end py-6">
              <div
                onClick={() => {
                  handleCheckPrivacy()
                  setShowMessage(false)
                }}
                className={`${checkPrivacy ? 'after:!bg-dark after:border-dark border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat' : ''} after:absolute after:content=[""] after:top-0 after:w-6 after:h-6  after:border after:border-checkBorder  after:left-0 pl-9 text-md leading-5 after:rounded-r4 relative cursor-pointer`}
              >
                I consent to Fit4me processing my health onboarding data to provide services and
                enhance my user experience.
                <Link
                  className="transition-all hover:text-primary underline text-[#0050B2]"
                  to="/privacy-policy"
                >
                  {t('Privacy Policy')}
                </Link>
              </div>
              <div
                className={`${!showMessage ? '-mb-14 opacity-0 invisible' : 'mb-0 opacity-100 visible animate-shake'} transition-all h-14 bg-[#EE505A] rounded-r16 p-4 text-white font-girloy flex items-center justify-between mt-4`}
              >
                Accept our Privacy Policy to continue
                <div>
                  <img
                    className="cursor-pointer min-w-6 text-white"
                    onClick={() => setShowMessage(false)}
                    src={close}
                    alt="close"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {props?.attributes?.name === 'current-weight' && (
        <div>
          <div className="relative mt-8">
            <div
              className={`${String(activeUnit) === 'lbs' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300 `}
            >
              <div className="flex items-center gap-2 p-2 border-b border-dark font-girloy">
                <input
                  className="block w-full text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75 "
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => setInputVal(String(e.target.value))}
                  placeholder="Your weight"
                  value={inputVal}
                />
                <span className="text-xl45 font-semibold block">lbs</span>
              </div>
            </div>
            <div
              className={`${String(activeUnit) === 'kg' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300`}
            >
              <div className="flex items-center gap-2 p-2 border-b border-dark font-girloy">
                <input
                  className="block w-full text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75 "
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => setInputVal(String(e.target.value))}
                  placeholder="Your weight"
                  value={inputVal}
                />
                <span className="text-xl45 font-semibold block">kg</span>
              </div>
            </div>
          </div>
          <BMITooltipBetterme bmi={bmi} answers={props.answers} unit={activeUnit} />
        </div>
      )}
      {props?.attributes?.name === 'goal-weight' && (
        <div>
          <div className="relative mt-8">
            <div
              className={`${String(activeUnit) === 'lbs' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300 `}
            >
              <div className="flex items-center gap-2 p-2 border-b border-dark font-girloy">
                <input
                  className="block w-full text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => setInputVal(String(e.target.value))}
                  placeholder="Goal weight"
                  value={inputVal}
                />
                <span className="text-xl45 font-semibold block">lbs</span>
              </div>
            </div>
            <div
              className={`${String(activeUnit) === 'kg' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300`}
            >
              <div className="flex items-center gap-2 p-2 border-b border-dark font-girloy">
                <input
                  className="block w-full text-xl45 font-semibold text-center outline-none placeholder:text-check-border placeholder:opacity-75"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => setInputVal(String(e.target.value))}
                  placeholder="Goal weight"
                  value={inputVal}
                />
                <span className="text-xl45 font-semibold block">kg</span>
              </div>
            </div>
          </div>
          {!isSmallBmi && (
            <>
              {goalWeight < 0 ? (
                <GetMoving betterme={true} percent={-goalWeight} lose="gain" />
              ) : (
                <GetMoving betterme={true} percent={goalWeight} lose="lose" />
              )}
            </>
          )}
          <BMITooltipBetterme bmi={bmi} percent={true} answers={props.answers} unit={activeUnit} />
        </div>
      )}
    </div>
  )
}
