import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/assets/scss/style.scss'
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom'
import Quiz from '@/components/Quiz/Quiz'
import Paywall from '@/components/Paywall/Paywall'
import i18n from './i18n'
import CreateAccount from '@/components/CreateAccount/CreateAccount'
import { FAQ } from './components/pages/FAQ/FAQ'
import { PrivacyPolicy } from './components/pages/PrivacyPolicy/PrivacyPolicy'
import { MoneyBackPolicy } from './components/pages/MoneyBackPolicy/MoneyBackPolicy'
import { Terms } from './components/pages/Terms/Terms'
import ThankYou from './components/ThankYou/ThankYou'
import { ConfirmEmail } from './components/ConfirmEmail'
import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import PaywallBetterme from '@/components/Paywall/PaywallBetterme'
import { Profitwell } from '@/components/Profitwell'
import { PrivacyPolicyNutrionika } from '@/components/pages/PrivacyPolicyNutrionika/PrivacyPolicyNutrionika'
import { TermsNutrionika } from '@/components/pages/TermsNutrionika/TermsNutrionika'

ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID ?? '')
amplitude.init('b2a79bfa738450505fcce812db525ad1', { defaultTracking: true })

const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 })
amplitude.add(sessionReplayTracking)

document.documentElement.lang = i18n.language

const queryParams = new URLSearchParams(window.location.search)
const quizVersion = true

localStorage.setItem('quizVersion', 'true')
// let quizVersion: boolean | string | null = localStorage.getItem('quizVersion')
// if (!quizVersion) {
//   quizVersion = Math.random() < 0.5
//   // localStorage.setItem('quizVersion', String(quizVersion))
// } else {
//   quizVersion = quizVersion === 'true'
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Router>
    <Routes>
      <Route
        path="/:currentStepString/:currentQuestionString"
        Component={(props) => (
          <Quiz
            startPath="/start"
            mainPath="/"
            nextPath="/0/0"
            {...props}
            lang={i18n.language}
            quizFile="quiz.json"
          />
        )}
      />
      <Route
        path="/start/"
        Component={(props) => (
          <Quiz
            startPath="/start"
            mainPath="/"
            nextPath="/0/0"
            {...props}
            lang={i18n.language}
            quizFile="quiz.json"
          />
        )}
      />
      <Route
        path="/quiz-2/:currentStepString/:currentQuestionString"
        Component={(props) => (
          <Quiz
            startPath="/start-2"
            mainPath="/quiz-2/"
            nextPath="/quiz-2/0/0"
            {...props}
            lang={i18n.language}
            quizFile="quiz-2.json"
          />
        )}
      />
      <Route
        path="/start-2/"
        Component={(props) => (
          <Quiz
            {...props}
            startPath="/start-2"
            mainPath="/quiz-2/"
            nextPath="/quiz-2/0/0"
            lang={i18n.language}
            quizFile="quiz-2.json"
          />
        )}
      />
      {quizVersion ? (
        <Route
          path="/"
          element={<Navigate to={`/start/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
        />
      ) : (
        <Route
          path="/"
          element={<Navigate to={`/start-2/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
        />
      )}
      <Route
        path="/paywall/"
        Component={(props) => (
          <Paywall
            paymentMethod={'stripe'}
            paywallFile={`${process.env.REACT_APP_MODE !== 'dev' ? 'paywall.json' : 'paywall-dev.json'}`}
            {...props}
            lang={i18n.language}
          />
        )}
      />
      <Route
        path="/paywall-2/"
        Component={(props) => (
          <PaywallBetterme
            paymentMethod={'stripe'}
            paywallFile={`${process.env.REACT_APP_MODE !== 'dev' ? 'paywall-betterme.json' : 'paywall-dev-betterme.json'}`}
            {...props}
            lang={i18n.language}
          />
        )}
      />
      <Route path="/confirm-email/" Component={(props) => <ConfirmEmail {...props} />} />
      <Route path="/profitwell/" Component={(props) => <Profitwell {...props} />} />
      <Route path="/register/" Component={(props) => <CreateAccount {...props} />} />
      <Route path="/faq/" Component={(props) => <FAQ {...props} />} />
      <Route path="/privacy-policy/" Component={(props) => <PrivacyPolicy {...props} />} />
      <Route
        path="/privacy-policy-nutrionika/"
        Component={(props) => <PrivacyPolicyNutrionika {...props} />}
      />
      <Route path="/money-back/" Component={(props) => <MoneyBackPolicy {...props} />} />
      <Route path="/terms/" Component={(props) => <Terms {...props} />} />
      <Route path="/terms-nutrionika/" Component={(props) => <TermsNutrionika {...props} />} />
      <Route path="/thank-you/" Component={(props) => <ThankYou {...props} />} />
      <Route
        path="/test-register/"
        Component={(props) => <CreateAccount testMode={true} {...props} />}
      />
    </Routes>
  </Router>,
)
