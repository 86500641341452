import React from 'react'
import { PaywallContent } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface PlansHeaderProps {
  paywallContent: PaywallContent | undefined
  isFreeTrial: boolean
  title: string
  goal: string[] | string | undefined
  goalWeight: string[] | string | undefined
}

export const PlansHeaderBetterme = (props: PlansHeaderProps) => {
  const { t } = useTranslation()
  const { paywallContent, isFreeTrial, goal, goalWeight } = props
  return (
    <>
      {paywallContent?.choosePlanBlock && (
        <>
          <h3 className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl45  font-semibold font-girloy  text-center mb-6 tracking-tight">
            {props.title}
          </h3>
          {isFreeTrial && paywallContent.choosePlanBlock.description && (
            <p className="text-dark font-medium opacity-50 text-center my-3">
              {paywallContent.choosePlanBlock.description}
            </p>
          )}
          <div
            className={`grid grid-cols-2 gap-4 relative after:absolute after:content-[''] after:top-0 after:left-1/2 after:-translate-x-1/2 after:h-full after:w-px after:bg-borderHeader ${isFreeTrial ? 'mb-0' : 'mb-6'}`}
          >
            <div className="flex items-center">
              <svg
                viewBox="0 0 33 33"
                width="1em"
                height="1em"
                data-icon="GoalOutlinedIcon"
                className="min-w-8 mr-2 mb-1 h-auto"
                fill="#EA749B"
              >
                <path
                  fillRule="evenodd"
                  d="M26.35 2.5a.823.823 0 0 0-.56.243L22.56 5.971a.824.824 0 0 0-.218.772l.526 2.221-8.563 8.563a.824.824 0 0 0 1.162 1.166l8.562-8.561 2.228.526a.824.824 0 0 0 .771-.219l3.228-3.228a.823.823 0 0 0-.395-1.386l-2.176-.516-.512-2.177a.824.824 0 0 0-.825-.632Zm-.44 2.45.293 1.234a.822.822 0 0 0 .608.614l1.233.291-1.854 1.86-1.732-.406-.407-1.734 1.859-1.86Zm-10.978.807a12.357 12.357 0 0 0-8.813 3.624c-4.825 4.825-4.825 12.675 0 17.5 4.825 4.825 12.67 4.825 17.495 0a12.38 12.38 0 0 0 2.211-14.498.824.824 0 0 0-1.459.766 10.717 10.717 0 0 1-1.913 12.566 10.71 10.71 0 0 1-15.166 0 10.711 10.711 0 0 1 0-15.166A10.715 10.715 0 0 1 19.85 8.633a.823.823 0 1 0 .765-1.459 12.354 12.354 0 0 0-5.683-1.417h-.001Zm-.108 4.85a7.515 7.515 0 0 0-5.277 2.203c-2.93 2.931-2.93 7.713 0 10.644 2.932 2.931 7.706 2.931 10.637 0a7.528 7.528 0 0 0 1.593-8.291.823.823 0 1 0-1.514.65 5.866 5.866 0 0 1-1.24 6.473 5.863 5.863 0 0 1-8.31 0 5.864 5.864 0 0 1 0-8.31 5.87 5.87 0 0 1 6.475-1.245.825.825 0 0 0 .65-1.514 7.495 7.495 0 0 0-3.014-.61Z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>
                <p className="opacity-70 text-xs2 font-medium ">{t('Goal')}</p>
                <p className="text-md font-semibold font-girloy leading-none">{goal}</p>
              </div>
            </div>
            <div className="flex items-center">
              <svg
                viewBox="0 0 33 33"
                width="1em"
                height="1em"
                data-icon="TargetWeightIcon"
                className="min-w-8 mr-2 mb-1 h-auto"
                fill="#EA749B"
              >
                <path
                  fillRule="evenodd"
                  d="m4.748 9.94.9 13.021.002.06c0 2.574 2.184 4.72 4.953 4.72h11.794c2.77 0 4.953-2.146 4.953-4.72 0-.02 0-.04.002-.06l.9-13.021c-.017-2.561-2.194-4.692-4.953-4.692H9.7c-2.759 0-4.936 2.13-4.953 4.692ZM3 9.968C3 6.363 6.034 3.5 9.701 3.5H23.3C26.966 3.5 30 6.363 30 9.968c0 .02 0 .04-.002.06l-.9 13.025c-.017 3.59-3.045 6.437-6.701 6.437H10.603c-3.656 0-6.684-2.846-6.701-6.437l-.9-13.024A.872.872 0 0 1 3 9.969Z"
                  clipRule="evenodd"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M16.74 7.234a12.291 12.291 0 0 0-8.256 3.117 2.848 2.848 0 0 0-.954 1.942 2.837 2.837 0 0 0 .696 2.05l1.915 2.199.002.001a2.451 2.451 0 0 0 3.102.474c.848-.51 2.114-1.08 3.496-1.08 1.381 0 2.648.57 3.496 1.08a2.451 2.451 0 0 0 3.102-.474l.001-.002 1.913-2.199c.493-.565.745-1.3.696-2.049a2.849 2.849 0 0 0-.954-1.942 12.291 12.291 0 0 0-8.256-3.117Zm-7.37 5.18c.016-.258.135-.505.339-.686a10.448 10.448 0 0 1 7.03-2.65c3.401 0 5.737 1.5 7.031 2.65a.993.993 0 0 1 .093 1.403l-1.912 2.199a.608.608 0 0 1-.76.11h-.002a9.76 9.76 0 0 0-2.662-1.123l.862-3.132c.13-.472-.16-.97-.647-1.115-.486-.145-.986.12-1.116.592l-.944 3.432c-1.834.015-3.417.76-4.39 1.346l-.002.001a.608.608 0 0 1-.76-.111L9.615 13.13a.993.993 0 0 1-.246-.717Z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>
                <p className="opacity-70 text-xs2 font-medium ">{t('Target weight')}</p>
                <p className="text-md font-semibold font-girloy leading-none">
                  {goalWeight?.[0]} {goalWeight?.[1]}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
