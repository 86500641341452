import React, { useEffect, useState } from 'react'
import Layout from '@/components/Layout'
// import { Transition } from 'react-transition-group'
import { useSearchParams } from 'react-router-dom'
import StripeCheckout from '@/components/StripeCheckout/Stripe'
import { useTranslation } from 'react-i18next'
import back from '@/assets/images/arrow-back.svg'
import { trackInitCheckout } from '@/helpers/facebookPixelEvents'
// import { PayProCheckout } from '@/components/PayProCheckout/PayProCheckout'
import Subtotal from '../CheckoutUI/Subtotal'
import { formatPlan } from '@/helpers/formatPlan'
// import PayPallIcon from '@/assets/images/PayPal.svg'
// import PaddleCheckout from '@/components/PaddleCheckout/Paddle'

// const transition = '300ms all, 300ms all'
// const transitionStylesModal = {
//   entered: {
//     top: '0px',
//   },
//   exited: {
//     top: '100%',
//   },
// }
// const defaultStyleModal = {
//   transition,
//   top: '100%',
// }

type PaymentTypes = 'paypro' | 'stripe'

interface CheckoutProps {
  handleBackToPaywall: () => void
  isDiscount: boolean
  isFreeTrial: boolean
}

export default function Checkout(props: CheckoutProps) {
  const { t } = useTranslation()
  // const nodeRef = useRef(null)
  const [searchParams] = useSearchParams()
  const [isLoadingStripe, setIsLoadingStripe] = useState(true)
  // const [isLoadingPaypro, setIsLoadingPaypro] = useState(true)
  const hasQuizResult = localStorage.getItem('quizResult') ?? false
  const plan = JSON.parse(localStorage.getItem('activePlan') ?? '')
  const reservePlan = JSON.parse(localStorage.getItem('reserveActivePlan') ?? '')
  const [paymentMethod, setPaymentMethod] = useState<PaymentTypes>('stripe')

  const handleReturnToPaywall = () => {
    if (!isLoadingStripe) {
      props.handleBackToPaywall()
    }
  }

  // const handleReturnToCheckout = () => {
  //   setPaymentMethod('stripe')
  //   window.history.go(-1)
  // }

  // const handleShowPaypro = () => {
  //   window.history.pushState('paypro', '', '')
  //   setPaymentMethod('paypro')
  // }

  useEffect(() => {
    if (!plan || !searchParams || !hasQuizResult) {
      handleReturnToPaywall()
    }
    trackInitCheckout(plan.name, 'InitiateCheckout_' + paymentMethod)
  }, [])

  useEffect(() => {
    const handlePopstate = () => {
      setPaymentMethod('stripe')
    }

    window.addEventListener('popstate', handlePopstate)
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return (
    <Layout>
      {hasQuizResult && (
        <div className="p-4  flex flex-col h-full overflow-y-auto overflow-x-hidden">
          {isLoadingStripe && (
            <div className="fixed bottom-0 z-30 left-0 bg-white w-full h-[calc(100%-60px)]">
              <div className="spinner z-30"></div>
            </div>
          )}

          <div className="z-[12] relative bg-white mb-3 -mt-4 -mx-4 p-4">
            <div
              className={
                'opacity-100 z-[12] visible cursor-pointer absolute top-1/2 left-4 -translate-y-1/2 transition-all duration-300 active:scale-95'
              }
              onClick={handleReturnToPaywall}
            >
              <img src={back} alt="arrow back" />
            </div>
            <h1 className="z-[11] text-center checkout-h1 text-xl3 text-black">
              {t('Select payment method')}
            </h1>
          </div>

          <div>
            <Subtotal
              isFreeTrial={props.isFreeTrial}
              isDiscount={props.isDiscount}
              plan={formatPlan(plan, props.isDiscount, props.isFreeTrial)}
            />
          </div>

          {/* <button
            className={
              'active:scale-[97%] active:border-[#13D16A] active:border-[3px] transition-all h-[50px] box-border flex gap-4 items-center justify-center px-4 py-3 text-xl font-semibold rounded-[5px] text-[#646369] border-[#E9E9E9] border'
            }
            onClick={handleShowPaypro}
          >
            <img src={PayPallIcon} alt="PaypallIcon" width={90} height={24} />
            <span>Buy Now</span>
          </button> */}

          <div className={'flex w-[calc(100%+1rem)] shrink grow basis-auto -mx-2'}>
            <StripeCheckout
              isDiscount={props.isDiscount}
              isFreeTrial={props.isFreeTrial}
              handleIsLoading={(loading: boolean) => setIsLoadingStripe(loading)}
              plan={plan}
              reservePlan={reservePlan}
              searchParams={searchParams}
            />
          </div>

          {/* <Transition nodeRef={nodeRef} timeout={100} in={paymentMethod === 'paypro'}>
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute px-4 left-1/2 z-[100] overflow-hidden -translate-x-1/2 h-full max-w-content w-full flex items-end bg-white top-0"
                >
                  <div className="z-[12] w-full absolute bg-white top-4 -mt-4 p-4 -mx-4">
                    <div
                      className={
                        'opacity-100 z-[12] visible cursor-pointer absolute top-1/2 left-4 -translate-y-1/2 transition-all duration-300 active:scale-95'
                      }
                      onClick={handleReturnToCheckout}
                    >
                      <img src={back} alt="arrow back" />
                    </div>

                    <h1 className="z-[11] text-center checkout-h1 text-xl3 text-black">
                      {t('Select payment method')}
                    </h1>
                  </div>
                  <div
                    className={
                      'flex shrink grow basis-auto w-full h-full -mt-4 -mx-[2.5rem] -mr-[3.5rem]'
                    }
                  >
                    <PayProCheckout
                      isDiscount={props.isDiscount}
                      isFreeTrial={props.isFreeTrial}
                      handleIsLoading={(loading: boolean) => setIsLoadingPaypro(loading)}
                      allowedPaymentMethod="paypal"
                      plan={plan}
                      searchParams={searchParams}
                    />
                  </div>
                </div>
              )
            }}
          </Transition> */}

          {/* {paymentMethod === 'paddle' && (
            <div className="flex w-full shrink grow basis-auto">
              <PaddleCheckout
                isDiscount={props.isDiscount}
                isFreeTrial={props.isFreeTrial}
                handleIsLoading={(loading: boolean) => setIsLoading(loading)}
                plan={plan}
                searchParams={searchParams}
              />
            </div>
          )} */}
        </div>
      )}
    </Layout>
  )
}
