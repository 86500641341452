import React, { useEffect, useRef } from 'react'
import { Answer, Answers, RadioButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import Image from 'react-image-webp'

interface RadioButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
  smallWidth?: boolean
}

export default function RadioButton(props: RadioButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = () => {
    props.onAnswer({
      [props.name]: [props.attributes.text],
    })
    props.triggerNextStep()
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      const inputs = document.querySelectorAll('input')
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
  }, [])
  return (
    <div>
      {!props.attributes?.withTick && (
        <label
          className={`
            font-semibold text-xl text-dark border transition duration-300 border-radio  mb-2 mt-0  rounded-xl cursor-pointer flex items-center bg-transparent active:scale-95 relative overflow-hidden after:absolute after:content=[''] after:top-1/2 after:w-5 after:h-5  after:border after:border-checkBorder after:bg-check after:right-5 after:rounded-md after:-translate-y-1/2 active:scale-95
            ${checkbox.current?.checked ? ' !bg-checked !border-green ' : ''}
            ${props.smallWidth ? ' w-2/3' : ''}
            ${
              checkbox.current?.checked
                ? ' !bg-checked border-green after:bg-green after:border-green border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
                : ''
            }
            `}
        >
          {props.attributes?.img && !props.attributes.imgWebp && (
            <img
              className={(props.attributes?.img && 'w-full') + ' max-w-full  w-auto'}
              width={358}
              height={96}
              alt="Body img"
              src={props.attributes?.img}
            />
          )}
          {props.attributes.imgWebp && (
            <Image
              className={(props.attributes?.img && 'w-full') + ' max-w-full  w-auto'}
              width={358}
              height={96}
              alt="Body img"
              src={props.attributes?.img}
              webp={props.attributes?.imgWebp}
            />
          )}
          <span className="absolute block top-1/2 -translate-y-1/2 left-28">
            {props.attributes.text}
          </span>
          <input
            type="radio"
            onClick={handleChange}
            name={props.name}
            value={props.attributes.text}
            className="hidden"
            ref={checkbox}
          />
        </label>
      )}
      {props.attributes?.withTick && (
        <label
          className={`
            flex bg-white items-center checkbox font-semibold border relative border-border mb-2 mt-0 rounded-xl px-3 py-2 pr-10 min-h-16 cursor-pointer  transition duration-300  after:absolute after:content=[''] after:top-1/2 after:w-5 after:h-5  after:border after:border-checkBorder after:bg-check after:right-5 after:rounded-md after:-translate-y-1/2 active:scale-95
            ${
              checkbox.current?.checked
                ? ' !bg-checked  border-green after:bg-green after:border-green border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
                : ''
            } ${props.smallWidth ? ' w-2/3' : ''}`}
        >
          {props.attributes?.img && (
            <span className="flex items-center min-w-6 max-w-6 mr-4">
              <img
                className="max-w-6 h-auto w-auto"
                width={24}
                height={24}
                alt="Radio img"
                src={props.attributes?.img}
              />
            </span>
          )}
          <span className={`${!props.attributes?.img ? 'pl-2' : ''} text-xl text-semibold`}>
            {props.attributes.text}
            {props.attributes?.small && (
              <small className="block  text-dark text-xs2 font-semibold opacity-65">
                {props.attributes.small}
              </small>
            )}
          </span>
          <input
            type="radio"
            onClick={handleChange}
            name={props.name}
            value={props.attributes.text}
            className="hidden"
            ref={checkbox}
          />
        </label>
      )}
    </div>
  )
}
