import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'

interface GraphProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
}
export const PersonalizedGraphicBetterme = (props: GraphProps) => {
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const eventDate = getValue('date-of-event', props.answers)
  const event = getValue('important-event', props.answers)
  const [loadAnimation, setLoadAnimation] = useState(false)
  const newDate = new Date()
  const newDateFit = new Date()
  newDateFit.setDate(newDate.getDate() + 41)
  let currentWeightNumber = 0
  let goalWeightNumber = 0
  let weight = 0
  if (currentWeight && goalWeight) {
    goalWeightNumber = +goalWeight?.[0]
    if (currentWeight?.[1] !== goalWeight?.[1] && goalWeight?.[1] === 'LBS') {
      currentWeightNumber = +currentWeight?.[0] * 2.2
    } else if (currentWeight?.[1] !== goalWeight?.[1] && goalWeight?.[1] === 'KG') {
      currentWeightNumber = +currentWeight?.[0] * 0.4536
    } else {
      currentWeightNumber = +currentWeight?.[0]
    }
    weight = +currentWeightNumber.toFixed(0) - +goalWeight?.[0]
  }
  useEffect(() => {
    if (props.active) {
      setTimeout(() => {
        setLoadAnimation(true)
      }, 500)
    } else {
      setLoadAnimation(false)
    }
  }, [props.active])
  return (
    <>
      <p className="text-[#171717b8] text-center text-base1 leading-8 -mt-4">
        We predict you’ll be <br />{' '}
        <span className="text-green text-xl2 font-semibold font-girloy">
          {goalWeight?.[0]} {goalWeight?.[1]}
        </span>{' '}
        by{' '}
        <span className="text-green text-xl2 font-semibold font-girloy">
          {newDateFit.toLocaleString('en-us', { day: 'numeric', month: 'long' })}
        </span>{' '}
        <br />
        {weight > 0
          ? `and lose ${weight} ${goalWeight?.[1]}`
          : `and gain ${-weight} ${goalWeight?.[1]}`}
      </p>

      {event && event !== 'No events any time soon' && eventDate && (
        <p className="text-center mt-2">
          {new Date(eventDate as string).toLocaleString('en-us', {
            day: 'numeric',
            month: 'short',
          })}{' '}
          - {event}
        </p>
      )}

      {weight < 0 && (
        <div className="rounded-r16 p-4 bg-grayB pl-12 pt-10 mt-6">
          <div
            className={`${loadAnimation ? 'betterme-graph--active' : ''} betterme-graph betterme-graph--gain border-t border-b border-borderHeader relative w-[271px] pb-1 after:absolute after:content-[''] after:w-full after:h-px after:left-0 after:top-1/2 after:-translate-y-1/2 after:bg-borderHeader`}
          >
            <p className="absolute  bottom-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full translate-y-1/2">
              {currentWeightNumber}
            </p>
            <p className="absolute top-1/2 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {((+goalWeightNumber + currentWeightNumber) / 2).toFixed(0)}
            </p>
            <p className="absolute top-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {goalWeight?.[0]}
            </p>
            <p className="betterme-goal z-10 absolute top-12 -right-2 text-md text-center text-white font-medium px-2.5 py-1.5 rounded-r8 leading-5 bg-green -translate-y-1/2 before:absolute before:content-['']  before:right-2 before:-top-1.5 before:w-3 before:h-3 before:bg-green before:rotate-45">
              Goal <br />
              {goalWeight?.[0] + ' ' + goalWeight?.[1]}
            </p>
            <svg
              viewBox="0 0 303 169"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="w-full charts-common_graphArea__gIM_r personalized-weight-graph-brand-palette-animations_graphArea__r78sZ"
            >
              <path
                opacity="0.2"
                d="M301 0.994171C188 1 216.666 57.411 135.5 83.5C51.5 110.5 24.5 159.5 2 167L0 167V169H303V0.994171H301Z"
                fill="url(#paint0_linear_2497_6786)"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_2497_6786"
                  x1="133.5"
                  y1="0"
                  x2="133.5"
                  y2="168"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#171717"></stop>
                  <stop offset="1" stopColor="#171717" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
            <div className="absolute w-0 overflow-hidden top-0 left-0  h-full betterme-line z-10">
              <svg
                viewBox="0 0 303 171"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                className="absolute top-0 left-0 w-[271px] charts-common_graphLine__hUnvs personalized-weight-graph-brand-palette-animations_graphLine__8Rkm_"
              >
                <path
                  d="M2 169C24.5 161.5 51.5 112.5 135.5 85.5C216.666 59.411 188 3 301 2.99417"
                  stroke="url(#paint0_linear_20274_146770_GainWeightChartLine)"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <defs>
                  <linearGradient
                    id="paint0_linear_20274_146770_GainWeightChartLine"
                    x1="1.23485"
                    y1="2.81298"
                    x2="224.877"
                    y2="43.4267"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF0000"></stop>
                    <stop offset="0.427083" stopColor="#FFE600"></stop>
                    <stop offset="1" stopColor="#30E3B7"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="flex items-center justify-between pt-2">
            <p className="text-xs2 opacity-75">Today</p>
            <p className="text-xs2 opacity-75">
              {newDateFit.toLocaleString('en-us', { day: 'numeric', month: 'short' })}
            </p>
          </div>
        </div>
      )}
      {weight >= 0 && (
        <div className="rounded-r16 p-4 bg-grayB pl-12 mt-6">
          <div
            className={`${loadAnimation ? 'betterme-graph--active' : ''} betterme-graph relative w-[271px] pb-1  border-t border-b border-borderHeader`}
          >
            <p className="absolute top-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {currentWeightNumber}
            </p>
            <p className="absolute top-1/2 -left-2 text-xs2 text-[#171717b8] -translate-x-full -translate-y-1/2">
              {((+goalWeightNumber + currentWeightNumber) / 2).toFixed(0)}
            </p>
            <p className="absolute bottom-0 -left-2 text-xs2 text-[#171717b8] -translate-x-full translate-y-1/2">
              {goalWeight?.[0]}
            </p>
            <p className="betterme-goal absolute bottom-0 -right-2 text-md text-center text-white font-medium px-2.5 py-1.5 rounded-r8 leading-5 bg-green -translate-y-1/2 before:absolute before:content-['']  before:right-2 before:-bottom-1.5 before:w-3 before:h-3 before:bg-green before:rotate-45">
              Goal <br />
              {goalWeight?.[0] + ' ' + goalWeight?.[1]}
            </p>
            <svg
              viewBox="0 0 303 168"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="w-full charts-common_graphArea__gIM_r personalized-weight-graph-brand-palette-animations_graphArea__r78sZ"
            >
              <path
                opacity="0.2"
                d="M301 166.994C188 167 152.765 79.9658 85 56C3 27 36.0767 13.5383 14 2.5C10 0.5 6.93399 0 2 0H0V168H303V166.994H301Z"
                fill="url(#paint0_linear_2497_6786)"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_2497_6786"
                  x1="133.5"
                  y1="0"
                  x2="133.5"
                  y2="168"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#171717"></stop>
                  <stop offset="1" stopColor="#171717" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
            <div className="absolute w-0 overflow-hidden top-0 left-0  h-full betterme-line">
              <svg
                viewBox="0 0 303 171"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                className="absolute top-0 left-0 w-[271px] charts-common_graphLine__hUnvs personalized-weight-graph-brand-palette-animations_graphLine__8Rkm_"
              >
                <path
                  d="M2 2C6.93399 2 10 2.5 14 4.5C36.0767 15.5383 3 29 85 58C152.765 81.9658 188 169 301 168.994"
                  stroke="url(#paint0_linear_19971_139592_LoseWeightChartLine)"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <defs>
                  <linearGradient
                    id="paint0_linear_19971_139592_LoseWeightChartLine"
                    x1="1.23485"
                    y1="1.81773"
                    x2="224.962"
                    y2="42.2063"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF0000"></stop>
                    <stop offset="0.427083" stopColor="#FFE600"></stop>
                    <stop offset="1" stopColor="#30E3B7"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="flex items-center justify-between pt-2">
            <p className="text-xs2 opacity-75">Today</p>
            <p className="text-xs2 opacity-75">
              {newDateFit.toLocaleString('en-us', { day: 'numeric', month: 'short' })}
            </p>
          </div>
        </div>
      )}
      <p className="text-xs2 text-[#171717b8] mt-4">
        *Based on the data of users who log their progress in the app. Consult your physician first.
        The chart is a non-customized illustration and results may vary
      </p>
    </>
  )
}
