import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/Layout'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { Answers, PaywallContent, sliderBlockItemType } from '@/types/types'
import Timer from '@/components/PaywallUI/Timer'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'
import { getValue } from '@/helpers/getObjectValue'
import arrow from '@/assets/images/accordion-arrow.svg'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion'
import { backCheckout, trackLead } from '@/helpers/facebookPixelEvents'
import Checkout from '@/components/Checkout/Checkout'
import Percent45Overlay from '@/components/PaywallUI/Percent45Overlay'
import FreeTrialOverlay from '@/components/PaywallUI/FreeTrialOverlay'
import ErrorBoundary from '@/components/ErrrosBoundary'
import HeroBetterme from '@/components/PaywallUI/HeroBetterme'
import { PlansHeaderBetterme } from '@/components/PaywallUI/PlansHeaderBetterme'
import { PlansBetterme } from '@/components/PaywallUI/PlansBetterme'
import { FeaturedBetterme } from '@/components/PaywallUI/FeaturedBetterme'
import ReviewsSlider from '@/components/PaywallUI/ReviewsSlider'
import Reviews from '@/components/PaywallUI/Reviews'
import ReviewPopup from '@/components/PaywallUI/ReviewPopup'

interface PaywallProps {
  lang: string
  paywallFile: string
  paymentMethod: string
}

export interface PaywallStatus {
  currentStep: number
  showModal: boolean
  wasShowing?: boolean
}

export default function PaywallBetterme(props: PaywallProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [showCheckout, setShowCheckout] = useState(false)
  const storedData = localStorage.getItem('quizResult') ?? false
  const paywallStatusString = localStorage.getItem('paywallStatus')
  const [paywallStatus, setPaywallStatus] = useState<PaywallStatus>(
    paywallStatusString
      ? (JSON.parse(paywallStatusString) as PaywallStatus)
      : { currentStep: 0, showModal: false },
  )
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const goal = getValue('main-goal', answers)
  const goalWeight = getValue('goal-weight', answers)
  const [paywallContent, setPaywallContent] = useState<PaywallContent>()
  const [activePlan, setActivePlan] = useState(1)
  const isFreeTrial = paywallStatus.currentStep >= 2
  const transition = '300ms all, 300ms all'
  const nodeRef = useRef(null)
  const planElement = useRef<HTMLDivElement>(null)
  const topPage = useRef<HTMLDivElement>(null)
  const duration = 500
  const transitionStyles = {
    entering: {
      opacity: 1,
      top: '0px',
      position: 'absolute',
      transition,
    },
    entered: {
      opacity: 1,
      top: '0px',
      position: 'absolute',
      transition,
    },
    exiting: {
      opacity: 0,
      top: '150px',
      position: 'absolute',
      transition: '300ms all',
    },
    exited: {
      opacity: 0,
      top: '150px',
      position: 'absolute',
      transition: '300ms all',
    },
  }
  const transitionStylesModal = {
    entered: {
      top: '0px',
    },
    exited: {
      top: '100%',
    },
  }
  const defaultStyle = {
    transition,
    opacity: 0,
  }
  const defaultStyleModal = {
    transition,
    top: '100%',
  }
  const [review, setReview] = useState<{ slide: undefined | sliderBlockItemType; isOpen: boolean }>(
    { slide: undefined, isOpen: false },
  )

  const handleChangePaywallStatus = (modal: boolean, step?: number) => {
    setPaywallStatus({ currentStep: step ?? paywallStatus.currentStep, showModal: modal })
  }

  useEffect(() => {
    localStorage.setItem('paywallStatus', JSON.stringify(paywallStatus))
    if (paywallStatus.currentStep >= 2) {
      setActivePlan(0)
    }
  }, [paywallStatus])

  const handlePlan = (index: number) => {
    setActivePlan(index)
  }

  const handleBackToPaywall = () => {
    window.history.go(-1)
    setShowCheckout(false)
    backCheckout()

    if (paywallStatus.currentStep === 0) {
      handleChangePaywallStatus(true, 1)
    }
  }

  const handleShowCheckout = () => {
    window.history.pushState('checkout', '', '')
    setShowCheckout(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await import(`../../locales/${props.lang}/${props.paywallFile}`)
        setPaywallContent(response)
      } catch (error) {
        console.error('Error fetching paywall data:', error)
      }
    }
    fetchData()
    trackLead()

    if (searchParams) {
      const paymentIntent = searchParams.get('payment_intent')
      if (paymentIntent) {
        handleShowCheckout()
      }
    }

    window.history.replaceState('paywall', '', '')
  }, [])

  useEffect(() => {
    const handlePopstate = (event: PopStateEvent) => {
      if (event.state !== 'checkout') {
        setShowCheckout(false)
        backCheckout()

        if (paywallStatus.currentStep === 0) {
          handleChangePaywallStatus(true, 1)
        }
      }
    }

    window.addEventListener('popstate', handlePopstate)
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [paywallStatus.currentStep])

  useEffect(() => {
    if (!answers) {
      navigate('/')
    }
  }, [answers])

  const handleToggleReview = (slide: sliderBlockItemType | undefined, isOpen: boolean) => {
    setReview({ slide, isOpen })
  }

  return (
    answers && (
      <Layout className="betterme">
        <div className="overflow-y-auto min-h-full p-4 px-5 pt-0 overflow-x-hidden">
          <div ref={topPage}></div>
          <div className="pb-2 pt-2 sticky top-0 bg-white z-50  flex items-center justify-between">
            <Timer />
            <Button
              className="font-semibold !py-2 btn-animation"
              onClick={() => planElement.current?.scrollIntoView()}
              variant="plan"
            >
              {isFreeTrial ? 'Start trial' : t('get my plan')}
            </Button>
          </div>
          {paywallStatus.currentStep === 1 && (
            <>
              <p className="text-center line-through">
                Previous discount: <span className="text-green2">61%</span>
              </p>
              <div className="bg-[#8DECB4] flex items-center mt-3 mb-6 py-3 px-4 rounded-2xl">
                <span className="text-[50px] mr-2">
                  <svg
                    viewBox="0 0 32 32"
                    width="1em"
                    height="1em"
                    data-icon="TagIcon"
                    className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeM__ckFGG svg-icon-module_colorModeDefault__tEprC additional-discount-banner_icon__Xpe_C"
                  >
                    <path
                      fillRule="evenodd"
                      d="M29.828 17.142c-.402 1.404-1.484 2.486-3.648 4.65l-2.561 2.561C19.854 28.118 17.972 30 15.633 30c-2.34 0-4.221-1.882-7.986-5.647C3.882 20.588 2 18.706 2 16.367c0-2.339 1.882-4.221 5.647-7.986l2.561-2.561c2.164-2.164 3.246-3.246 4.65-3.648 1.403-.403 2.894-.059 5.876.63l1.72.396c2.508.579 3.762.868 4.62 1.727.86.86 1.15 2.113 1.728 4.622l.397 1.72c.688 2.98 1.032 4.472.63 5.875Zm-11.2-7.763a2.823 2.823 0 1 0 3.992 3.993 2.823 2.823 0 0 0-3.993-3.993Zm-12.5 6.693 9.771 9.77a1.05 1.05 0 1 0 1.485-1.484l-9.77-9.771a1.05 1.05 0 1 0-1.486 1.485Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="text-center font-bold">
                  Get your personal plan with up to <strong>71%</strong> discount
                </p>
              </div>
            </>
          )}
          <HeroBetterme />
          <div ref={planElement} className=""></div>

          <div id="plan" className="mt-12">
            <PlansHeaderBetterme
              title="Your Wall Pilates Workout Plan is ready!"
              paywallContent={paywallContent}
              isFreeTrial={isFreeTrial}
              goal={goal}
              goalWeight={goalWeight}
            />
            {paywallContent?.plans && (
              <PlansBetterme
                activePlan={activePlan}
                isDiscountPrice={paywallStatus.currentStep === 1}
                isFreeTrial={isFreeTrial}
                handleShowCheckout={handleShowCheckout}
                handlePlan={handlePlan}
                paymentMethod={props.paymentMethod}
                descriptionTrial={paywallContent.plans.descriptionTrial}
                plans={paywallContent?.plans.items}
                plansReserve={paywallContent?.plans_reserve.items}
              />
            )}
          </div>

          {paywallContent?.benefitsBlock && (
            <div className="mt-12">
              <h3 className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl45  font-semibold font-girloy  text-center mb-6 tracking-tight">
                Highlights of your plan
              </h3>
              {paywallContent?.benefitsBlock?.items && (
                <ul className="list-none">
                  {paywallContent?.benefitsBlock?.items?.map((el, index) => (
                    <li className="flex items-center mt-4" key={index}>
                      <div className="max-w-12 min-w-12 h-12 rounded-full  mr-3">
                        <img className="w-full" src={el.src} alt="Icon" />
                      </div>
                      <div>
                        <p className="font-semibold leading-5 text-base1">{el.title}</p>
                        <p className="text-base1">{el.text}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {paywallContent?.featuredBlock && (
            <div className="px-5 py-10  mt-12 -mx-5 bg-grayB">
              <h3
                className="[&>mark]:bg-transparent  text-xl45  font-semibold font-girloy  text-center mb-6 tracking-tight"
                dangerouslySetInnerHTML={{ __html: paywallContent.featuredBlock.title }}
              ></h3>
              <div className="flex items-center justify-center text-[#1717178f] flex-wrap gap-8">
                <FeaturedBetterme />
              </div>
            </div>
          )}

          {paywallContent?.testimonialsBlock && (
            <div className="mt-12 ">
              <ReviewsSlider
                slides={[
                  {
                    name: 'Eun',
                    img: '/img/betterme/reviews/review-0.png',
                    review:
                      'It helps you track everything you need when you want to help yourself lowering weight or keeping it, from water to food and calories and many other, adding to that the 1 to 1 conversations are supper helpful the trainings help so much it’s extremely good.',
                    res: '-5kg',
                  },
                  {
                    name: 'Nina',
                    img: '/img/betterme/reviews/review-2.png',
                    review:
                      "I am 4 months postpartum and I was stuck at the last bit of my pregnancy weight. Well I decided to try this app out and after 2 weeks of doing the workouts I started seeing results. My waist was trimmer, mom belly was starting to go away, and my legs began to be more toned. I love doing the daily workout on my schedule in the app and then l've been incorporating yoga as well that is also on the app.",
                    res: '-3kg',
                  },
                  {
                    name: 'Nguyet',
                    img: '/img/betterme/reviews/review-1.png',
                    review:
                      'Growing up I never battled with my weight. Eating whatever I wanted without seeing any negative consequences, those bad habits led to developing bad habits. However, Those bad habits quickly caught up with me after my hysterectomy. I tried one diet after another with little to no success. Fit4Me not only has helped me form good eating habits, but they have educated me along the way. I feel empowered now! I have the tools I need to continue to reach my health and weight goals! The app is easy to use and the constant coaching is tremendously motivating. I love the challenges! The 21 day no sugar challenge combined with intermittent fasting helped me to lose 13lbs! I feel great and can’t wait to see where my weight loss journey takes me!',
                    res: '-6kg',
                  },
                ]}
                onOpenReview={handleToggleReview}
                title="Results that make us proud"
              />
              <p className="text-xs2 opacity-55">
                <b>*Disclaimer:</b> Following exercise and a meal plan is the key in your fitness
                journey and greatly impacts the results. It’s unusual to lose more than 8 pounds per
                month. Consult a physician first.
                <br />
              </p>
            </div>
          )}

          {paywallContent?.faqBlock && (
            <div className="mt-12">
              <h3
                className="[&>mark]:bg-transparent  text-xl45  font-semibold font-girloy  text-center mb-6 tracking-tight"
                dangerouslySetInnerHTML={{ __html: paywallContent.faqBlock.title }}
              ></h3>
              <Accordion type="single" collapsible defaultValue="item-0">
                {paywallContent?.faqBlock.items?.map((item, index) => (
                  <AccordionItem
                    key={index}
                    className="border-b border-border"
                    value={`item-${index}`}
                  >
                    <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-xl2 font-semibold font-girloy w-full flex items-center justify-between text-left">
                      {item.title}
                      <img
                        className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4 opacity-75"
                        src={arrow}
                        alt="arrow"
                      />
                    </AccordionTrigger>
                    <AccordionContent className="overflow-hidden text-base1 data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                      <p className="pb-6 text-left opacity-75 text-base1">{item.text}</p>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}

          <div className="mt-12 ">
            <Reviews
              slides={[
                {
                  name: 'Amber',
                  review:
                    'I had my doubts but the app really helped me to be consistent with my workouts. I’ve never done wall Pilates before so didn’t know what to expect. I’m surprised how much more toned and flexible that I feel.',
                },
                {
                  name: 'Amanda',
                  review:
                    "Highly recommend for those needing motivation. This app has helped me better myself and help me with my weight loss journey. The app is very easy to use and with the everyday reminders and coaching, it's kept me more motivated than any other workout regime that I've ever used!",
                },
                {
                  name: 'Tila',
                  review:
                    'I’ve been using the app since February 2023 and lost about 15 pounds and lots of inches. I love how it gears to my age, limitations and always give me encouragement to keep going. The app is simple and keeps me on track.',
                },
              ]}
              onOpenReview={handleToggleReview}
              title="Users love our plans"
            />
            <p className="text-xs2 mt-4 opacity-55">
              <b>*Disclaimer:</b> Following exercise and diet plans is the key in your fitness
              journey and greatly impacts results. In 4 weeks users can typically expect to lose not
              more than 0.45-0.90 kg per week. Individual results may vary.
            </p>
          </div>

          <ReviewPopup slide={review.slide} isOpen={review.isOpen} onClose={handleToggleReview} />

          <div className="mt-12">
            <PlansHeaderBetterme
              title="Get visible results in 4 weeks!"
              paywallContent={paywallContent}
              isFreeTrial={isFreeTrial}
              goal={goal}
              goalWeight={goalWeight}
            />
            {paywallContent?.plans && (
              <PlansBetterme
                activePlan={activePlan}
                isDiscountPrice={paywallStatus.currentStep === 1}
                isFreeTrial={isFreeTrial}
                handleShowCheckout={handleShowCheckout}
                handlePlan={handlePlan}
                paymentMethod={props.paymentMethod}
                descriptionTrial={paywallContent.plans.descriptionTrial}
                plans={paywallContent?.plans.items}
                plansReserve={paywallContent?.plans_reserve.items}
              />
            )}
          </div>

          {paywallContent?.moneyBackBlock && (
            <div className="p-4 rounded-r16 bg-grayB mt-12">
              <svg
                viewBox="0 0 48 48"
                width="1em"
                height="1em"
                data-icon="SecurityCheckOutlineIcon"
                className="w-12 mx-auto h-auto mb-4 svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeM__ckFGG svg-icon-module_colorModeDefault__tEprC money-back-guarantee_icon__u1suP"
              >
                <path
                  fillOpacity="0.72"
                  fillRule="evenodd"
                  d="M24 5.93c-1.214 0-2.409.414-5.39 1.493l-1.057.383c-2.793 1.012-4.83 1.75-6.259 2.365-.714.306-1.23.564-1.594.787a3.04 3.04 0 0 0-.385.271.727.727 0 0 0-.1.1.82.82 0 0 0-.06.142 3.55 3.55 0 0 0-.117.486c-.08.443-.141 1.05-.184 1.871-.084 1.643-.085 3.937-.085 7.079v3.076c0 10.11 7.145 15.085 11.906 17.286.685.317 1.081.496 1.522.616.422.114.935.185 1.803.185.868 0 1.381-.071 1.803-.185.44-.12.837-.3 1.522-.616 4.761-2.2 11.906-7.176 11.906-17.286v-3.076c0-3.142 0-5.436-.085-7.079-.043-.82-.104-1.428-.184-1.871a3.557 3.557 0 0 0-.118-.486.817.817 0 0 0-.059-.142.727.727 0 0 0-.1-.1 3.035 3.035 0 0 0-.385-.271c-.363-.223-.88-.48-1.594-.787-1.43-.615-3.466-1.353-6.259-2.365l-1.057-.383C26.409 6.343 25.214 5.93 24 5.93Zm-6.05-1.365C20.618 3.599 22.27 3 24 3c1.73 0 3.383.599 6.05 1.565l.236.086 1.106.4c2.735.991 4.845 1.755 6.356 2.405.761.327 1.416.644 1.943.968.506.311 1.015.7 1.36 1.22.342.516.519 1.149.63 1.758.115.634.185 1.394.23 2.267C42 15.4 42 17.775 42 20.855v3.128c0 11.918-8.507 17.633-13.566 19.971l-.05.023c-.628.29-1.217.563-1.895.746-.715.194-1.475.277-2.489.277-1.014 0-1.774-.083-2.49-.277-.677-.183-1.267-.456-1.894-.746l-.05-.023C14.507 41.616 6 35.901 6 23.984v-3.13c0-3.078 0-5.452.09-7.185.044-.873.114-1.633.229-2.267.111-.61.288-1.242.63-1.757.345-.521.853-.91 1.36-1.221.527-.324 1.182-.641 1.943-.968 1.51-.65 3.621-1.414 6.356-2.405l1.105-.4.238-.086Z"
                  clipRule="evenodd"
                  fill="#171717b8"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M17.5 22.835a1.634 1.634 0 0 0-2.401-.028 1.823 1.823 0 0 0-.027 2.477l5.143 5.578c.325.353.779.551 1.254.54.476-.013.92-.234 1.227-.601l10.286-12.273a1.822 1.822 0 0 0-.14-2.473c-.7-.66-1.777-.59-2.395.147L21.37 27.033l-3.87-4.198Zm15.245-6.286c.358.508.343 1.23-.07 1.722L22.39 30.544a1.256 1.256 0 0 1-.93.457c-.357.01-.701-.14-.95-.41l-5.144-5.578 5.143 5.578c.25.27.594.42.95.41.357-.009.694-.174.93-.457L32.676 18.27a1.428 1.428 0 0 0 .07-1.722Z"
                  clipRule="evenodd"
                  fill="#3AAE92"
                ></path>
              </svg>
              <h3 className="[&>mark]:bg-transparent  text-xl35  font-semibold font-girloy  text-center mb-4 tracking-tight">
                30-Day Money-Back Guarantee
              </h3>
              <p className="w-full text-base1 opacity-75 tracking-[-.03em]">
                We believe that our plan may work for you and you’ll get visible results in 4 weeks!
                We even are ready to return your money back if you don’t see visible results and can
                demonstrate that you followed our plan.
                <br />
                <br />
                Find more about applicable limitations in our{' '}
                <Link
                  className="transition-all hover:text-primary text-[#0050B2] underline"
                  to="/money-back"
                  dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.link }}
                ></Link>
              </p>
            </div>
          )}

          {paywallContent?.copyright && (
            <p
              className=" py-4 pb-0 text-center text-xs2  opacity-55 [&>span]:px-px"
              dangerouslySetInnerHTML={{ __html: paywallContent.copyright }}
            ></p>
          )}

          <Transition
            nodeRef={nodeRef}
            timeout={100}
            onExited={() => topPage.current?.scrollIntoView()}
            in={paywallStatus.currentStep === 1 && paywallStatus.showModal}
          >
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute left-1/2 z-[100] -translate-x-1/2 h-full max-w-content w-full flex items-end backdrop-blur-md top-0"
                >
                  <Percent45Overlay
                    betterme={true}
                    isShow={paywallStatus.showModal && paywallStatus.currentStep === 1}
                    onCloseOverlay={() => {
                      handleChangePaywallStatus(false)
                    }}
                  />
                </div>
              )
            }}
          </Transition>

          <Transition
            nodeRef={nodeRef}
            onExited={() => planElement.current?.scrollIntoView()}
            timeout={100}
            in={paywallStatus.currentStep === 2 && paywallStatus.showModal}
          >
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute left-1/2 z-[100] -translate-x-1/2 h-full max-w-content w-full flex items-end backdrop-blur-md top-0"
                >
                  <FreeTrialOverlay
                    isShow={paywallStatus.showModal && paywallStatus.currentStep === 2}
                    onCloseOverlay={() => {
                      handleChangePaywallStatus(false)
                    }}
                  />
                </div>
              )
            }}
          </Transition>

          <Transition nodeRef={nodeRef} in={showCheckout} timeout={duration}>
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyle,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStyles[state],
                  }}
                  className={`bg-white ${showCheckout ? 'absolute z-50 w-full h-full left-0' : ''}`}
                >
                  <ErrorBoundary>
                    {showCheckout && (
                      <Checkout
                        isDiscount={paywallStatus.currentStep === 1}
                        isFreeTrial={isFreeTrial}
                        handleBackToPaywall={handleBackToPaywall}
                      />
                    )}
                  </ErrorBoundary>
                </div>
              )
            }}
          </Transition>
        </div>
      </Layout>
    )
  )
}
