import { sliderBlockItemType } from '@/types/types'
import React from 'react'

interface ReviewsProps {
  slides: sliderBlockItemType[]
  title: string
  onOpenReview: (review: sliderBlockItemType, isOpen: boolean) => void
}

const Reviews = (props: ReviewsProps) => {
  return (
    <div className="flex flex-col">
      <div>
        <p className="[&>mark]:bg-transparent  text-xl35  font-semibold font-girloy  text-center mb-2 tracking-tight ">
          {props.title}
        </p>
      </div>
      <div className="w-full">
        {props.slides.map((slide) => (
          <div className="rounded-r16 bg-grayB p-4 mt-4" key={slide.name}>
            <div className="flex flex-col">
              <p className="text-base1 font-semibold font-girloy">{slide.name}</p>
              <div className="flex mt-2">
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  data-icon="StarFullIcon"
                  className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
                  fill="#E9A43F"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  data-icon="StarFullIcon"
                  className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
                  fill="#E9A43F"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  data-icon="StarFullIcon"
                  className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
                  fill="#E9A43F"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  data-icon="StarFullIcon"
                  className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
                  fill="#E9A43F"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  data-icon="StarFullIcon"
                  className="svg-icon-module_svgRoot__VmjSs svg-icon-module_iconSizeXS__GUJwa svg-icon-module_colorModeDefault__tEprC review-with-rating_icon__kpXpd"
                  fill="#E9A43F"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.916 1.658c-.278-.877-1.554-.877-1.832 0L5.878 5.456H1.96c-.917 0-1.311 1.133-.582 1.675l3.21 2.387-1.223 3.851c-.276.869.756 1.57 1.498 1.018L8 12.055l3.136 2.332c.742.551 1.774-.15 1.498-1.018l-1.222-3.851 3.21-2.387c.728-.542.334-1.675-.583-1.675h-3.917L8.916 1.658Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <p className="text-[#171717b8] text-base1 mt-4 relative tracking-[-.03em] reviews-text">
                {slide.review}
                <button
                  className="text-[#0050b2] absolute bottom-0 right-0 w-16 text-end bg-more text-md"
                  onClick={() => props.onOpenReview(slide, true)}
                >
                  more
                </button>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Reviews
