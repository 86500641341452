import React from 'react'
import { Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import Image from 'react-image-webp'

const currentBodyFat: Record<string, string> = {
  '0': '10-14%',
  '1': '15-19%',
  '2': '20-24%',
  '3': '25-29%',
  '4': '30-34%',
  '5': '35-39%',
  '6': '40-44%',
  '7': '>45%',
}

interface HeroProps {
  answers: Answers
}

export default function Hero(props: HeroProps) {
  const { t } = useTranslation()
  const rangeCurrentBody = getValue('range-current-body', props.answers)?.[0] ?? '0'

  return (
    <div className="pb-1.5 rounded-r15 border border-borderInput mt-1">
      <div className="flex relative items-center mt-4 mb-3 h-[40px]">
        <div className="absolute w-[2px] h-[100%] bg-[#E6EFE6] left-[calc(50%-1px)] top-0"></div>
        <h2 className="text-center w-[50%] text-xl3 font-bold m-0 p-0">Now</h2>
        <h2 className="text-center w-[50%] text-green2 text-xl3 font-bold m-0 p-0">Goal</h2>
      </div>
      <div className="relative flex h-[150px] mb-4 overflow-hidden">
        <img
          className="absolute w-[100%] h-[100%] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          src="/img/paywall-arrow.png"
          alt="Arrow"
        />
        <div className="w-[50%] relative">
          <Image
            className="h-full mx-auto"
            src={`/img/range/range-${rangeCurrentBody}.png`}
            webp={`/img/range/range-${rangeCurrentBody}.webp`}
            alt="body image"
          />
        </div>
        <div className="w-[50%] h-full relative">
          <Image
            className="h-full mx-auto"
            src={'/img/range/range-0.png'}
            webp={'/img/range/range-0.webp'}
            alt="body image"
          />
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-3 px-1.5">
        <div>
          <div className="bg-lightGray p-3 rounded-lg mt-2">
            <p className="opacity-50 text-md2 font-semibold leading-none">{t('Body fat:')}</p>
            <p className="text-md2 font-bold leading-6">{currentBodyFat[rangeCurrentBody]}</p>
            <p className="opacity-50 text-md2 font-semibold leading-none mt-3">
              {t('Fitness level:')}
            </p>
            <p className="text-md2 font-bold leading-6">{t('Low')}</p>
            <div className="flex mt-1">
              <span className="w-6 h-2 rounded-r2 bg-green2 block mr-2"></span>
              <span className="w-6 h-2 rounded-r2 bg-level block mr-2"></span>
              <span className="w-6 h-2 rounded-r2 bg-level block mr-2"></span>
              <span className="w-6 h-2 rounded-r2 bg-level block"></span>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-checked p-3 rounded-lg mt-2">
            <p className="opacity-50 text-md2 font-semibold leading-none">{t('Body fat:')}</p>
            <p className="text-md2 font-bold leading-6">
              {rangeCurrentBody === '0' ? currentBodyFat['0'] : currentBodyFat['1']}
            </p>
            <p className="opacity-50 text-md2 font-semibold leading-none mt-3">
              {t('Fitness level:')}
            </p>
            <p className="text-md2 font-bold leading-6">{t('High')}</p>
            <div className="flex mt-1">
              <span className="w-6 h-2 rounded-r2 bg-green2 block mr-2"></span>
              <span className="w-6 h-2 rounded-r2 bg-green2 block mr-2"></span>
              <span className="w-6 h-2 rounded-r2 bg-green2 block mr-2"></span>
              <span className="w-6 h-2 rounded-r2 bg-green2 block"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
