import { Header } from '@/components/Header/Header'
import React from 'react'
import logo from '@/assets/images/logo.svg'
import { Button } from '@/components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { PaddleProvider } from '@/components/PaddleCheckout/PaddleProvider'

export const Profitwell = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/start')
  }

  return (
    <div className="w-full relative py-5 px-4 flex flex-col pt-3  h-full overflow-y-auto overflow-x-hidden pb-20">
      <PaddleProvider
        handleIsLoading={() => {
          console.log('success loading paddle')
        }}
      >
        <h1 className="text-green relative w-image max-w-image -ml-4 pb-3 border-b border-borderInput text-center font-semibold">
          Welcome!
        </h1>
        <div className="absolute top-4 right-4 w-full">
          <Header />
        </div>
        <div className="mt-3 pt-1">
          <img
            className="max-w-40 w-full mx-auto mt-8"
            width="156"
            height="27"
            src={logo}
            alt="logo"
          />
        </div>
        <Button onClick={handleClick} className="mt-6">
          Get started
        </Button>
      </PaddleProvider>
    </div>
  )
}
